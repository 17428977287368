/* footer */
footer {
  &.footer {
    padding-bottom: 84px;
    background-color: $vdr-footer-grey-bg;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    margin: 0;
    padding-top: 30px;
    z-index: 0;
    @include breakpoint($screen-md-min){
      padding-bottom: 33px;
    }
    .footer-block{
      .d-flex {
        display: block;
        text-align: center;

        @include breakpoint($screen-md-min) {
          display: flex;
          text-align: initial;
        }

        &.justify-content-between {
          justify-content: space-between;
          align-items: center;
        }
      }

      .special {
        .copyright {
          margin: 2rem 0 1rem;
          text-align: center;

          @include breakpoint($screen-md-min) {
            margin: 0;
            text-align: right;
          }
        }
      }

      .copyright {
        clear: both;
        font-size: 16px;
        line-height: normal;
        color: $colBG;
        text-align: right;

        a {
          color: $colBG;
        }
      }

      .vdr-social {
        margin-left: 30px;
      }
    }
    .block-menu-block{
      @media (max-width: $screen-sm-max) {
        display: none;
      }
      .menu-block-wrapper{
        ul{
          li{
            color: $vdr-footer-font-grey;
            .nolink{
              font-weight: 600;
              font-size: 16px;
              line-height: 23px;
              padding: 0;
            }
            a{
              color: $vdr-footer-font-grey;
              font-size: 16px;
              line-height: 23px;
              font-weight: 300;
            }
          }
        }
        > ul.menu.nav {
          display: flex;
          flex-wrap: wrap;

          > li {
            display: inline-block;
            width: 229px;
            margin-right: 10px;

            &.leaf, &.last {
              a {
                font-weight: 600;
                padding-left: 0;
                padding-top: 5px;
              }
            }

            @media (max-width: $screen-md-max) {
              &:nth-child(n+4) {
                padding-top: 15px;
              }
            }
          }
        }

      }
    }
    #block-menu-menu-footer{
      > ul.menu.nav{
        margin-right: 0;
        > li{
          display: inline-block;
          a{
            color: $colBG;
            font-size: 16px;
            padding: 0;
            &:after{
              color: $colBG;
              content: "|";
              font-size: 16px;
              display: inline-block;
              margin-left: 5px;
              margin-right: 5px;
            }
          }

          &:last-child{
            a{
              &:after{
                display: none;
              }
            }

          }
        }
      }
    }
    .mobile-login{
      width: 100%;
      position: absolute;
      bottom: 0;

      .btn{
        width: 100%;
        height: 50px;
        border-top: 1px solid #fff;
        border-radius: 0;
        font-size: 20px;
        line-height: 34px;
        font-family: $fontHeadlines;
      }

    }



  }
  ul {
    list-style: none;
  }
  .top:after {
    clear: both;
    content: " ";
    display: block;
    margin-bottom: 20px;
  }
  .footer-share-icons {
    font-size: 10px;
    ul {
      float:left;
      li {
        float:left;
        &:nth-child(3n){
          clear:left;
        }
      }
    }
    a {
      color: $colBG;
      .fa-inverse {
        color: $colMinor;
      }
    }
  }
  .logo-invers-akb,
  .logo-invers-awb,
  .logo-invers-vdr,
  .logo-invers-vme,
  .logo-invers-wazb,
  .logo-invers-wveb {
    background: url($imgLogoInv) no-repeat right;
    float: right;
    height: 64px;
    width: 137px;
  }

  .input-group .form-control,
  .input-group .input-group-addon {
    width: auto;
  }
  .form-item-masquerade-user-field label {display:none;}
  #masquerade-block-1 {
    margin: 20px auto;
    width: 300px;
    .form-item .form-control, .form-item .form-control + .input-group-addon {
      color: #000;
      font-size: 15px;
      border-radius:0;
    }
    #edit-submit {margin: -10px 0 0 3px;}
    .form-item {
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      margin: 5px 0;
    }
  }

}

.modal.fade {
  .modal-dialog {
    transform: translate3d(0, 0, 0);
    @include breakpoint(max-width $screen-sm-max){
      width: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 50px;
    }

    @media only screen and (max-width: $screen-sm-max) and (max-height: 460px){
      height: calc(100% - 50px);
      overflow-y: scroll;
    }

    @include breakpoint($screen-md-min){
      transform: translate3d(-50%, -50%, 0);
      width: 740px;
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
    }
    .modal-content{
      background-color: $vdr-font-blue;
      border-radius: 0;
      .modal-body{
        padding: 30px;
        @include breakpoint($screen-md-min){
          padding: 40px;
        }
      }
      .vdr-login-link{
        text-align: center;
        margin-bottom: 25px;
        @include breakpoint($screen-md-min){
          margin-bottom: 0;
          margin-right: 25px;
          display: inline;
        }
        &:last-child{
          margin-bottom: 0;
          @include breakpoint($screen-md-min){
            margin-right: 0;
          }
        }
        a{
          text-align: center;
          font-size: 20px;
          line-height: 34px;
          font-family: $fontHeadlines;
          color: #fff;
          border: 1px solid #fff;
          width: 200px;
          height: 120px;
          display: inline-block;
          padding: 20px 0 60px 0;
          position: relative;
          margin: auto;
          @include breakpoint($screen-md-min){
            height: 170px;
            padding: 50px 0 60px 0;
          }
          //@include vdr-icon(40, '../img/more2.svg');
          @include vdr-login-more-icon('../img/more5.svg');
          &:hover, &:focus{
            text-decoration: none;
          }
          @include hoverscale(1.05);
        }
      }
    }
  }

}
