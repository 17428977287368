@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }  
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }  
  @keyframes $animation-name {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin yellowModal {
  background: $colSecond;
  border-top: solid 3px $colFont;
  border-radius: 0;
}

@mixin flag($file){
/**** DBG *******
******  #{$file}
***********/
}

@mixin bg-gradient{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f0f4fa+0,ebeff5+50,d4d9e0+100 */
  background: #f0f4fa; /* Old browsers */
  background: -moz-linear-gradient(top, #f0f4fa 0%, #ebeff5 50%, #d4d9e0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f0f4fa 0%,#ebeff5 50%,#d4d9e0 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f0f4fa 0%,#ebeff5 50%,#d4d9e0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f4fa', endColorstr='#d4d9e0',GradientType=0 ); /* IE6-9 */
}

@mixin slider-headline-bg-gradient{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#09a898+1,469baf+100 */
  background: rgb(9,168,152); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(9,168,152,0.75) 1%, rgba(70,155,175,0.75) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(9,168,152,0.75) 1%,rgba(70,155,175,0.75) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(9,168,152,0.75) 1%,rgba(70,155,175,0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#09a898', endColorstr='#469baf',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin vdr-icon($size, $url){
  line-height: $size+px;
  padding: 4px 0 4px 6+$size+px;
  line-height: $size+px;
  a{
    padding-left: 4px;
    line-height: 1.2em;
    display: inline-block;
  }
  &:before{
    position: absolute;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
    background-size: $size+px $size+px;
    display: inline-block;
    content: "-";
    color: transparent;
    width: $size+px;
    height: $size+px;
    margin-left: -($size+6)+px;
  }
}

@mixin vdr-link-icon($size, $url) {
  min-height: 20px;
  display: inline-block;
  margin-left: ($size+15)+px;
  margin-top: (($size - 15)/2)+px;
  @include breakpoint($screen-md-min){
    margin-top: (($size - 28)/2)+px;
    min-height: 30px;
  }
  &:before {
    position: absolute;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
    background-size: $size+px $size+px;
    display: inline-block;
    content: "-";
    color: transparent;
    width: $size+px;
    height: $size+px;
    margin-left: -($size + 15)+px;
    margin-top: -(($size - 17)/2)+px;
    @include breakpoint($screen-md-min){
      margin-top: -(($size - 30)/2)+px;
    }
  }
}

@mixin vdr-link-icon-responsive-hoverscale($sizeXS, $sizeMD, $url, $scalefactor){
  @include vdr-link-icon($sizeXS, $url);
  @include breakpoint($screen-md-min){
    @include vdr-link-icon($sizeMD, $url);
  }
  @include hovericonscale(1.1);
  @include hoverscale(1.01);
  transform-origin: 0 50%;
}

@mixin vdr-more-icon($url){
  &:after{
    content: "-";
    color: transparent;
    float: none;
    position: absolute;
    bottom: -16px;
    width: 32px;
    height: 32px;
    padding: 3px 12px 3px 12px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
    background-size: 32px 32px;
    @media all and (min-width: $screen-md-min) {
      width: 40px;
      height: 40px;
      bottom: -20px;
      padding: 8px 16px 8px 16px;
      background-size: 40px 40px;
    }
  }
}

@mixin vdr-list-more-icon($url){
  &:after{
    content: "-";
    color: transparent;
    float: none;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
    background-size: 40px 40px;
    @media all and (min-width: $screen-sm-min) {
      width: 40px;
      height: 40px;
      background-size: 40px 40px;
      right: 0;
      bottom: 50px;
      left: auto;
      transform: translate(0, 0);
    }
  }
}

@mixin vdr-footer-more-icon($url){
  &:after{
    content: "-";
    color: transparent;
    float: none;
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: -20px;
    padding: 8px 16px 8px 16px;
    background-size: 40px 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $vdr-cyan;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
  }
}

@mixin  vdr-login-more-icon($url){
  &:after{
    content: "-";
    color: transparent;
    float: none;
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 18px;
    padding: 8px 16px 8px 16px;
    background-size: 40px 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $vdr-font-blue;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($url);
  }
}

@mixin hoverscale($factor){
  transition: all 0.2s;
  &:hover{
    transform: scale($factor);
  }
}

@mixin hovericonscale($factor){
  &:hover{
    &:before{
      transition: all 0.2s;
      transform: scale($factor);
    }
  }
}
