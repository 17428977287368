.field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity,
.paragraphs-items-field-element-teaser .entity,
.paragraphs-items-field-themenbild-themenseite .entity,
.paragraphs-items-field-element-startseite-zweispaltig .entity,
.paragraphs-items-field-themenbild.paragraphs-items-field-themenbild-themenseite .entity,
.paragraphs-items-field-footer-element.paragraphs-items-field-footer-element-zweispaltig .entity,
.paragraphs-items-field-element.paragraphs-items-field-element-zweispaltig .entity,
.view-beitraege.view-display-id-block_1 .node-beitrag.view-mode-beitrag_teaser,
.view-beitraege.view-display-id-block_2 .node-beitrag.view-mode-beitrag_teaser, 
.view-beitraege.view-display-id-block_1 .views-field-rendered-entity > .field-content,
.view-beitraege.view-display-id-block_2 .views-field-rendered-entity > .field-content {
  cursor: pointer;
  margin-bottom: 40px;
  position: relative;

  &.paragraphs-item-bild,
  &.paragraphs-item-download-portal,
  &.paragraphs-item-presseabbinder,
  &.paragraphs-item-pressemeldungen {
    @include hoverscale(1.02);
  }
  @media all and (min-width: $screen-md-min) {
    margin-bottom: 56px;
  }
  .fields{
    position: relative;
  }
  .group-teaser-bg{
    padding: 10px 15px 20px 15px;
    background-color: #fff;
    min-height: 120px;
    @media all and (min-width: $screen-sm-min) {
      min-height: 180px;
    }

    @media all and (min-width: 992px) {
      /*min-height: 296px;*/
      padding: 20px 20px 30px 20px;
    }
    @media all and (min-width: 1200px) {
      //height: unset;
      min-height: 245px;
    }
    @include vdr-more-icon('../img/more1.svg');
  }
  .field-name-verlinkung-teaser.term-id-4860 + .group-teaser-bg, .field-name-verlinkung-teaser.term-id-4865 + .group-teaser-bg{
    //red
    @include vdr-more-icon('../img/more3.svg');
  }
  .field-name-verlinkung-teaser.term-id-4861 + .group-teaser-bg, .field-name-verlinkung-teaser.term-id-4867 + .group-teaser-bg{
    // cyan
    @include vdr-more-icon('../img/more2.svg');
  }
  .field-name-verlinkung-teaser.term-id-4862 + .group-teaser-bg, .field-name-verlinkung-teaser.term-id-4866 + .group-teaser-bg{
    // green
    @include vdr-more-icon('../img/more4.svg');
  }

  .field-name-field-presse-text,
  .field-name-field-download-text {
    color: #fff;
  }

  .field-name-verlinkung-teaser, .field-name-node-link{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    a{
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

    }
  }
  .field-name-field-datum{
    font-size: 15px;
    line-height: 23px;
    @media all and (min-width: $screen-md-min) {
      font-size: 18px;
    }
  }
  .field-name-field-verlinkung,
  .group-teaser-bg > .field-name-title > h2{
    font-family: $fontHeadlines;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: left;
    margin-bottom: 0;
    @media all and (min-width: $screen-md-min) {
      font-size: 24px;
      line-height: 27px;
    }
    a{
      color: $vdr-font-blue;
      font-weight: 600;
    }
  }
  .field-name-field-anrisstext {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 10px;
    margin-top: 0;
    font-weight: 400;
    font-family: $fontGlobal;
    &:after{
      border-bottom: 0;
      display: none;
    }
    @media all and (min-width: $screen-md-min) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  .field-name-field-tax-beitragsart, .field-name-field-beitragsart{
    position: absolute;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: -37px;
    margin-left: -15px;
    width: auto;
    color: #fff;
    height: 27px;
    overflow: hidden;
    @media all and (min-width: $screen-md-min) {
      font-size: 16px;
      margin-top: -47px;
      margin-left: -20px;
    }
    > div{
      background-color: $vdr-font-blue;
      padding: 3px 10px 4px 10px;
      &.term-id-4862, &.term-id-4866{
        background-color: $vdr-green;
      }
      &.term-id-4861, &.term-id-4867{
        background-color: $vdr-cyan-light;
      }
      &.term-id-4860, &.term-id-4865{
        background-color: $vdr-bordeaux;
      }
    }    
  }

  &.paragraphs-item-pressemeldungen,
  .views-field-rendered-entity {
    &.view-mode-startseite {
      ul.list-inline {
        display: none;
      }
    }
    ul.list-inline {
      display: none;
    }
  }
}
.view-beitraege.view-display-id-block_1,
.view-beitraege.view-display-id-block_2 {
  .view-content > .row {
    display: flex;
    flex-wrap: wrap;
    > .views-row {
      float: none;
      .field-content {
        display: inline-block;
        background-color: #fff;
        
        @include breakpoint($screen-md) {
          height: calc( 100% - 56px );
        }
        
        @include hoverscale(1.02);
        @media screen and (max-width: ($screen-sm-max)) {
          width: 100%;
        }
      } 
      @media screen and (max-width: ($screen-xs-max)) {
        width: 100%;
      }     
    }
  }
  .views-field-rendered-entity {    
    ul.list-inline {
      display: none;
    }
    @media screen and (max-width: $screen-sm-max) {
      img {
        width: 100%;
      }
    }
    @include breakpoint($screen-md) {
      height: 100%
    }    
  }
}
.view-id-beitraege.view-display-id-block_2{
  margin-top: 40px;
}
.view-beitraege.view-display-id-block_1 .node-beitrag.view-mode-beitrag_teaser, .view-id-beitraege.view-display-id-block_2 .node-beitrag.view-mode-beitrag_teaser{
  @include hoverscale(1.02);
}
.paragraphs-items-field-element-startseite-zweispaltig{
  margin-bottom: 20px;
  @media all and (min-width: $screen-md-min) {
    margin-bottom: 60px;
  }
  .paragraphs-item-bild.view-mode-startseite_zweispaltig{
    .group-teaser-bg{
      min-height: 120px;
      @media all and (min-width: $screen-sm-min) {
      }
      @media all and (min-width: $screen-md-min) {
        min-height: 150px;
      }
    }
  }
}

.paragraphs-item-presseabbinder,
.paragraphs-item-download-portal {

  @media all and (max-width: $screen-md-max) and (min-width:$screen-md-min) {
    padding-left: 10px;
    padding-right: 10px;

  }
  .field-group-html-element {

    @media all and (max-width: $screen-md-max) and (min-width:$screen-md-min) {
      padding: 10px 15px 15px 15px !important;
    }

    @media all and (max-width: $screen-md-max) and (min-width:$screen-md-min) {
      .field-name-field-presse-text,
      .field-name-field-download-text {
        font-size: 15px;
      }
    }
  }
}

#block-system-main .paragraphs-items-field-themenbild.paragraphs-items-field-themenbild-startseite {

  .field-name-field-foto {
    picture {
      @media all and (min-width: $screen-md-max)   {
        max-height: 175px;
      }
      img {
        @media all and (max-width: $screen-sm-max) {
          width: 100%;
        }
      }
    }
  }

  .first {
    .entity {
      /*height: 420px;*/
      height: unset;

      @media all and (min-width: $screen-xs) {
        height: 347px;
      }

      @media all and (min-width: $screen-sm-min) {
        height: 347px;
      }

      @media all and (min-width: 992px) {
        height: 420px;
      }

      @media all and (min-width: 1200px) {
        height: 420px;
      }



      .field-group-fieldses.group-teaser-bg {
        /*position: absolute;*/
        bottom: 0;
        background: transparent linear-gradient(180deg, #FFFFFF00 0%, #363636 100%) 0% 0% no-repeat padding-box;
        background-color: transparent;
        min-height: 170px;

        @media all and (min-width: $screen-xs) {
          position: absolute;
          color: #fff;
        }

        @media all and (min-width: $screen-sm) {
          position: absolute;
          color: #fff;
          min-height: 135px;
        }
        @media all and (min-width: 992px) {
          height: 175px;
        }
        @media all and (min-width: 1200px) {
          height: 190px;
        }



        .field-name-field-verlinkung {
          a {
            color: white;
          }
        }

        .field-name-field-tax-beitragsart {
          padding-left: 0;

          @media all and (min-width: $screen-xs) {
            padding-left: 15px;
          }
          @media all and (min-width: $screen-sm) {
            padding-left: 15px;
          }

          @media all and (min-width: 992px) {
            padding-left: 20px;
          }
        }

        @media all and (max-width: ($screen-xs - 1px)) {
          background: none;
          background-color: #fff;

          .field.field-name-field-verlinkung.field-type-link-field {
            a {
              color: #005FA0;
              font-weight: 600;            }
          }

          .field-name-field-anrisstext {
            color: #000 ;
          }
        }


      }

      .field-name-field-foto {
        position: relative;
        /*height: 420px;*/
        height: 100%;
        width: 100%;

        @media all and (min-width: $screen-xs) {
          position: absolute;
        }

        picture {
          height: 100%;
          max-height: none;
          img{
            max-height: none;
            /*height: 420px; */

            @media all and (min-width: $screen-xs) {
              height: 348px;
              width: 100%;
            }
            @media all and (min-width: $screen-sm-min) {
              height: 348px;
              width: 100%;
            }

            @media all and (min-width: 992px) {
              height: 100%;
            }

            @media all and (min-width: 1200px) {
              height: 100%;
            }

            @media all and(max-width: $screen-sm-min) {
              /*max-height: 420px;*/
            }


          }
        }
      }
    }
  }

  
  .field-items {
    margin-left: 0px;
    margin-right: 0px;
    .row {
      display: flex;
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
      > .field-item {
        float: none;
        > * {
          background-color: #fff;
          
          @include breakpoint($screen-md) {
            height: calc( 100% - 56px );
          }        
        }

        @media screen and (max-width: ($screen-sm-max)) {
          width: 100%;
        }
      }

    } 
  }  
}

.paragraphs-items-field-themenbild.paragraphs-items-field-themenbild-themenseite{
  margin-top: 20px;
  .entity{
    .field-name-field-foto{

    }
    .group-teaser-bg{
      min-height: 70px !important;
      @include breakpoint($screen-md-min){
        min-height: 114px !important;
      }
    }

    .field-name-field-verlinkung{
      a{
        font-size: 16px;
        line-height: 19px;
        @include breakpoint($screen-md-min){
          font-size: 24px;
          line-height: 27px;
        }
      }

    }
  }
}

.paragraphs-items-field-footer-element.paragraphs-items-field-footer-element-zweispaltig .entity{
  .field-name-field-foto{
    float: left;
  }
  .group-teaser-bg{
    background-color: $vdr-cyan;
    min-height: 160px;
    max-height: 160px;
    height: 160px;
    overflow: hidden;
    padding-left: 27px;
    @include vdr-footer-more-icon('../img/more5.svg');
    .field-name-field-verlinkung a, .field-name-field-anrisstext{
      color: #fff;
    }
    .field-name-field-anrisstext{
      max-height: 75px;
      overflow-y: hidden;
    }
  }
}

.front{

  .field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity {
    .group-teaser-bg{
      @media all and (min-width: 1200px) {
        height: unset;
      }

    }
  }

  .field-name-element.field-type-ds{
    .title, .view, .view .views-row, .view .views-field, .block-title{

      @media all and (min-width: $screen-md-min) {
        display: inline-block;
      }
    }
    .title, .view .views-field, .block-title{

      @media all and (min-width: $screen-md-min) {
        margin-right: 35px;
      }
    }
    > .title{
      @media (max-width: $screen-sm-max) {
        text-align: center;
      }
    }
    .view.view-beitragsart .views-row{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
        .views-field{
          margin-right: 0;
        }
      }
      .field-content{
        @media (max-width: $screen-sm-max) {
          display: block;
          text-align: center;
        }
        > a, > span{

          @media (max-width: $screen-sm-max) {
            display: inline-block;
          }
        }
      }
    }
    .title, .block-title{
      font-size: 21px;
      font-weight: 600;
      color: $vdr-font-blue;
      @include breakpoint($screen-md-min){
        font-size: 25px;
      }
    }
    .view{
      margin-top: 25px;
      @media all and (min-width: $screen-md-min) {
        margin-top: 10px;
      }
      .views-row{
        .field-content{
          a.span, span{
            display: block;
            color: #fff;
            padding: 7px 15px 8px 15px;
            background-color: $vdr-font-blue;
            font-weight: 600;
            font-size: 20px;
            &.term-id-4862, &.term-id-4866{
              background-color: $vdr-green;
            }
            &.term-id-4861, &.term-id-4867{
              background-color: $vdr-cyan-light;
            }
            &.term-id-4860, &.term-id-4865{
              background-color: $vdr-bordeaux;
            }
          }
          a{
            @include hoverscale(1.1);
            display: block;
          }
        }
      }
    }
  }

  .paragraphs-items-field-themenbild{
    margin-bottom: 24px;
  }
  .field-name-field-element{
    margin-top: 20px;
    margin-bottom: 0;
    @media all and (min-width: $screen-md-min) {
      margin-top: 40px;
      margin-bottom: 44px;
    }
  }
  .paragraphs-items-field-footer-element{
    margin-bottom: 24px;
    margin-top: 20px;
    @media all and (min-width: $screen-md-min) {
      margin-top: 40px;
    }
  }
}

.view-listen.view-display-id-block_2, .view-listen.view-display-id-block_3, .view-id-listen.view-display-id-block_12{
  .views-row{
    border-bottom: 1px solid $vdr-font-blue;
    padding-bottom: 32.5px;
    margin-bottom: 32.5px;
    @include breakpoint(max-width $screen-xs-max){
      padding-bottom: 90px;
    }
    @include breakpoint($screen-sm){
      padding-right: 70px;
    }
    .node-beitrag.view-mode-teaser{
      .field-name-field-foto{
        float: left;
        margin-right: 40px;
        @include breakpoint(max-width $screen-xs-max){
          float: none;
          margin-right: 0;
          width: auto;
          height: auto;
        }
      }
      .field-name-title{
        font-size: 30px;
        line-height: 36px;
        color: $vdr-font-blue;
        margin: 10px 0;
        font-family: $fontHeadlines;
        @include breakpoint(max-width $screen-xs-max){
          font-size: 20px;
          line-height: 24px;
        }
        @include breakpoint($screen-md-min){
          margin: 20px 0;
        }
      }
      .field-name-field-datum span, .field-name-field-ort, .field-name-field-anrisstext{
        font-weight: 400;
      }
      &:hover, &:focus{
        .field-name-title{
          color: $colFourth;
          transition: all 0.5s ease 0s;
        }
      }
    }
    .field-name-node-link{
      width: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      a{
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        display: block;
        color: transparent;
        top: 0;
        @include vdr-list-more-icon('../img/more1.svg');
        /*&:after{
          content: "\f105";
          float: none;
          font-family: FontAwesome;
          font-size: 36px;
          position:absolute;
          right: 0;
          bottom: 50px;
          width: 40px;
          height: 40px;
          color: #fff;
          padding: 8px 16px 8px 16px;
          background-color: $vdr-font-blue;
          @include breakpoint(max-width $screen-xs-max){
            right: initial;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
          }

          @include breakpoint($screen-md-min){
            padding: 6px 16px 6px 16px;
          }
        }*/
      }

    }
  }
}


.paragraphs-item-bild.view-mode-startseite.no-img{
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  .group-teaser-bg{
    margin-top: 49%;
    @include breakpoint($screen-sm-min){
      margin-top: 168px;
    }
    @include breakpoint($screen-md-min){
      margin-top: 142px;
    }
    @include breakpoint($screen-lg-min){
      margin-top: 176px;
    }
  }
  &.beitragsart-1, &.beitragsart-4864{
    background-image: url('../img/Pressemitteilung-L.jpg');
  }
  &.beitragsart-4862, &.beitragsart-4866{
    background-image: url('../img/Publikation-L.jpg');
  }
  &.beitragsart-4861, &.beitragsart-4867{
    background-image: url('../img/Dokumente-L.jpg');
  }
  &.beitragsart-4860, &.beitragsart-4865{
    background-image: url('../img/Veranstaltung-L.jpg');
  }
}

.paragraphs-items-field-footer-element.paragraphs-items-field-footer-element-zweispaltig .entity{
  .field-name-verlinkung-teaser{
    width: calc(100% - 40px);
  }
  > .field-name-field-foto{
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

}
.twitter-block, .linkedin-block {
  height: 100%;
  @include breakpoint($screen-sm-min){
    height: 348px;
  }
  @include breakpoint($screen-md-min){
    height: 441px;
  }
  @include breakpoint($screen-lg-min){
    height: 100%;
  }
   overflow: hidden;
}
#twitter-widget-0, #linkedin-widget-0 {
  width: 100% !important;
  height: 100% !important;
}

.field-name-field-vorgefertigte-views{
  .view-listen{
    .node-beitrag.node-teaser{
      .field-name-field-datum .date-display-single, .field-name-field-ort, .field-name-field-anrisstext{
        font-weight: 400 !important;
      }
      .field-name-field-datum, .field-name-field-ort{
        font-size: 15px;
        @include breakpoint($screen-md-min){
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }
}

.view-beitraege.view-display-id-block_1,
.view-beitraege.view-display-id-block_2{
  .views-row{
    padding-right: 20px;
    > .views-field{
      padding: 0 !important;
      margin: 0 !important;
    }
  }

}