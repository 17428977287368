$colBG:									#fff;
$colFont:								#000;
$colSubnavi:							#fff0af;

/* Primärfarbe: */
$colSecond:								#ffe164;
$colSecondTrans:						rgba(255,225,100,0.95);

/* active-Grau: */
$colMinor:								#696969;
$colThird:								#4f81bd;
/* hover-Grau: */
$colFourth:								#808080;

/* Sekundärfarbe: */
$colFifth:								#5082be;
$colFifthOverlay:						rgba(80, 130, 190, 0.5);					

$colBorder1:							#eee;
$colBorder2:							#ddd;
$colInputTxt:							#fff;

$imgDots:								'../img/bodyBackground.png';
$imgLogoInv:							'../img/vdr_logo_neg.png';
$imgLogo:								'../img/logo.png';
$imgLogoMobil:							'../img/logo-mobil.png';

$fontGlobal:							'Source Sans Pro', sans-serif;
$fontHeadlines:							'Titillium Web', sans-serif;
$fontLight:								300;
$fontRegular:							400;

$sizeStandard:							20px;
$sizeMobile: 							15px;
$sizeMenu:								19px;
$sizeSubmenu:							19px;

$heightNavbarMobile:				50px;
$heightNavbar:							65px;
$heightNavbarFront:					100px;

$vdr-font-blue: 					#005FA0;
$vdr-font-hover: 					#005FA0;
// $vdr-link: 							#005DA2;  Is there a difference?
$vdr-cyan:								#005FA0; //namen ändern

$bg-gradient-grey: 				#EBEFF5;
$bg-gradient-grey-light: 	#F0F4FA;
$bg-gradient-grey-dark: 	#D4D9E0;

$vdr-footer-grey-bg: 			#8B97A2;
$vdr-footer-font-grey:		#fff;

/* rubric colors */
// Presse, term-id-1 is $vdr-font-blue
// Publication, term-id-4862
$vdr-green: 							#869134; 
// RSS-Feed, term-id-4389
// Veranstaltungen, term-id-4860
$vdr-bordeaux: 						#842125;
// VDR-Document, term-id-4861
$vdr-cyan-light:					#AF7338; //namen ändern




.logo > div {
	background-image:url($imgLogoMobil);
    height: 36px;
    width: 106px;
	background-size: contain;
	background-repeat:no-repeat;
}
@media (min-width:768px) {
	.logo > div {
		margin: 23px 0;
		height: 30px;
		width: 89px;
	}
}
@media (min-width:992px) {
	.logo > div {
		background-image:url($imgLogo);
		height: 37px;
		width: 220px;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 20px 0;
	}
}
