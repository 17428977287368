.flex-startseite-bannertexte-control-nav.flex-startseite-bannertexte-control-paging {
  display: none;
  /*position:relative;*/
  list-style:none;
  padding:0 0 0 15px;
  /*top: -47%; */
  position: absolute;
  top: 55px;
  margin-bottom: 0;
  @media (max-width:767px) {
    text-align:center;
    width:100%;
    top:420px;
    padding:0;
  }
  @media (max-width:479px) {
    text-align:center;
    width:100%;
    top:220px;
    padding:0;
  }
  li {
    display:inline-block;
    margin-right:5px;
    padding:0;
    line-height:normal;
    a {
      font-size:0;
      background-color:$colBG;
      width:13px;
      height:13px;
      @media (max-width:767px) {
        background-color:$colSecond;
        &.flex-startseite-bannertexte-active{
          background-color:$colBG;
        }
      }
      padding:5px 6px 6px 5px;
      display:block;
      cursor:pointer;
      &:hover,
      &.flex-startseite-bannertexte-active{
        background-color:$colFont;
        transition: all 0.5s ease 0s;
      }
    }
  }
}
#block-views-slideshow-block-2 {
  position:fixed;
  height: 750px !important;
  left: 0;
  top: 0;
  width: 100%;
  @media (max-width:767px){
    height: 400px !important;
  }
}
#flexslider-2 {
  position: absolute;
  bottom: 100px;
  width: 100%;
  @media (max-width:767px){
    p {
      display:none;
    }
    bottom: 0;
  }
}
#flexslider-2 .slides li {
  position: absolute;
  top: 50px;
  //width: 400px !important;
  //border-color: $colFont;
  //border-style: solid;
  //border-width: 5px 0 0;
  @media (max-width:767px) {
    border-width:0;
  }
  @media (max-width: 1199px) {
    top: 0;
  }
  color: $colFont;
  letter-spacing: 0;
  /*margin-right: -10000px;*/
  min-height: 176px;
  padding: 35px 15px 15px;
  position: relative;
  text-transform: none;
  //width: 435px;
  z-index: 0 !important;
  visibility:hidden;
  &.flex-startseite-bannertexte-active-slide {
    visibility:visible;
  }

  @media (max-width:767px) {
    padding: 0;
  }

}

/*.flexslider,*/
#flexslider-1 {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  height: 100% !important;
  left: 0;
  margin: 0 !important;
  position: fixed;
  top: $heightNavbarMobile;
  -webkit-transition: top 0.5s; /* Safari */
  transition: top 0.5s;
  .field-name-field-bannerbild{
    .background-image{
      background-position: center;
    }
  }
  @include breakpoint($screen-md-min){
    top: $heightNavbarFront;
  }
  width: 100% !important;
}
body.front-down{
  #flexslider-1{
    top: $heightNavbarMobile;
    @include breakpoint($screen-md-min){
      top: $heightNavbar;
    }

  }
}
.flexslider {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  @media(max-width:767px){
    position: static;
  }
}
#block-views-slideshow-block-1 {
  height: 321px !important;
  @include breakpoint($screen-md-min){
    height: 750px !important;
  }
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100% !important;
  z-index:0;

  .flexslider{
    ul.slides{
      li{
        .field-name-field-bannerbild{
          .background-image{
          }
        }
        &.scaleup{
          .field-name-field-bannerbild{
            .background-image{
              transition: all 10s;
              -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
#block-views-slideshow-block-1 .view-slideshow,
#block-views-slideshow-block-1 .view-slideshow * {
  height: 321px !important;
  @include breakpoint($screen-md-min){
    height: 650px !important;
  }
}
#block-views-slideshow-block-1 .background-image {
  /*@include background-size(cover);*/

  &.large {
    display: none;
    /*@include breakpoint($screen-lg){*/
    @media(min-width:1200px){
      display: block;
      background-size:cover;
    }
  }
  &.medium {
    display:none;
    /*@include breakpoint($screen-md-min $screen-md-max){*/
    @media(min-width:992px) and (max-width:1199px){
      display: block;
      background-size:cover;
    }
  }
  &.small {
    display:none;
    /*@include breakpoint($screen-sm-min $screen-sm-max){*/
    @media(min-width:768px) and (max-width:991px){
      display: block;
      background-size:cover;
    }
  }
  &.mobile {
    display:none;
    /*@include breakpoint(max-width $screen-xs-max){*/
    @media(max-width:767px){
      display: block;
      background-size:cover;
    }
  }
}
.field-name-field-slideshow .paragraphs-item-bannerbild .content,
.field.field-name-field-bannerbild.field-type-image.field-label-hidden  {
  height: 100% !important;
  overflow: hidden;
  width: 100% !important;
}
.not-front #block-coma-beitrag-header {  
	height: 273px;
  @include breakpoint($screen-sm-min){
		height: 405px;
	}

  .field-name-field-bannerbild {
    overflow: hidden;
  }
} 

.field-name-field-bannerbild .img-responsive {
  //max-width: 100% !important;
  //width: 100% !important;
}
body .mm-page.mm-slideout #page-main-container #page-header .region.region-header #block-views-slideshow-block-3 .view-slideshow .view-content .views-row.views-row-1.views-row-odd.views-row-first.views-row-last,
#block-views-slideshow-block-3 .view-slideshow .view-content .views-row.views-row-1.views-row-odd.views-row-first.views-row-last {
  width:100%;
  height:250px;
  background-size:cover;
}

 .node-startseite .view-slideshow {
  &.container {
    width: unset;
  }
}

.flexslider .slides > li {
  height: 100% !important;
  overflow: hidden;
}
.flexslider .slides img {
  display: block;
  min-height: 100% !important;
  overflow: hidden;
  min-width: 100% !important;
}
.flexslider .slides img.img-responsive {
  width: auto !important;
}


// VDR Zahlenslider
.front{
  #block-views-slideshow-block-6{
    background: url("../img/wellenmuster.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
    .flexslider{
      margin-bottom: 70px;
      @media all and (min-width: $screen-md-min) {
        margin-bottom: 90px;
      }
    }
    h2{
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      padding: 24px 0;
      margin: 0;
      line-height: 28px;
      @media all and (min-width: $screen-md-min) {
        font-size: 71px;
        padding: 40px 0;
        line-height: 85px;
      }
      &.block-title{
        font-size: 30px;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 0;
        line-height: 36px;
        @media all and (min-width: $screen-md-min) {
          font-size: 38px;
          line-height: 46px;
          padding-top: 46px;
        }
      }
    }
    p{
      text-align: center;
      padding: 0 20%;
      font-size: 20px;
      line-height: 23px;
    }
    ul.flex-direction-nav{
      > li{
        > a{
          height: 80px;
          margin-top: -40px;
          &:focus, &:active, &:hover{
            outline: none;
          }
          &:before{
            font-size: 70px;
            line-height: 80px;
            color: #fff;
            font-family: FontAwesome;
          }
          &.flex-prev:before{
            content: "\f104";
          }
          &.flex-next:before{
            content: "\f105";
          }
        }
      }
    }
    .flex-control-nav{
      bottom: -36px;
      position: relative;
      @media all and (min-width: $screen-md-min) {
        bottom: -40px;
      }
      li{
        a{
          width: 20px;
          height: 20px;
          background-color: $vdr-font-blue;
          &.flex-active{
            background-color: #fff;
          }
        }
      }
    }
  }
  .field-name-zahlen-slideshow{
    margin-top: 60px;
  }
}

.front{
  #block-views-slideshow-block-7{
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 28vh;
    @media screen and (min-width: $screen-xs) and (max-width: $screen-xs-max){
      top: 30vh;
    }
    @include breakpoint($screen-sm){
      top: 30vh;
    }
    @include breakpoint($screen-md-min){
      top: calc(100px + 10vw);
    }
  }
  .field-name-field-zeile-teasertext {
    font-size: 20px;
    line-height: 31px;
    font-family: $fontHeadlines;
    letter-spacing: 1px;
    color: #fff;
    @media screen and (min-width: $screen-xs) and (max-width: $screen-xs-max){
      font-size: 30px;
      line-height: 31px;
    }
    @include breakpoint($screen-sm){
      font-size: 35px;
      line-height: 50px;
      letter-spacing: 0;
    }
    @include breakpoint($screen-md-min){
      font-size: 55px;
      line-height: 90px;
    }
    .field-item{
      display: flex;
      margin-bottom: 8px;      
          
      span{
        padding: 5px 10px 5px 10px;
        font-weight: 700;
        text-transform: uppercase;

        @include breakpoint($screen-xs){
          padding: 5px 10px 5px 10px;
        }
        
        @include breakpoint($screen-md-min){
          padding: 5px 20px 5px 20px;
        }
        /*@include slider-headline-bg-gradient;*/
        background: rgba(0, 95, 160, 0.36);
        opacity: 1;
        backdrop-filter: blur(8px);        
        -webkit-backdrop-filter: blur(8px);
        
      }
    }

    &:hover {
      text-decoration:none;
    }

  }
  h1, h1 a {
    font-size:38px;
    @media (max-width:767px){
      font-size: 28px;
    }
    border-bottom:none !important;
    margin-top:0;
    padding-top: 0;
  }
}
