.climate {
    .climate-box {
        
        width: 100%;
        background-color: #ACD5EA;

        &.box-light {
            background-color: transparent;
        }
        
    }
    .climate-header,
    .climate-footer {
        background-color: #005FA1;
        color: white;

        h1,
        h3 {
            margin: 0;            
            color: white;            
            text-transform: uppercase;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 2rem;   
        }

        h3 {
            padding-top: 0;
                     
        }
    }

    .climate-text,
    .climate-h3 {
        font-weight: 600;
        padding: 0 50px;
        @media screen and (max-width: 1199px) {
            padding: 0 42px;
        }
        @media screen and (max-width: 992px) {
            padding: 0 31px;
        }
        @media screen and (max-width: 767px) {
            padding: 0 4.2vw;
        }
    }

    .climate-footer {
        padding: 3rem 0;

        p {
            text-align: center;
        }
        
    }

    p {
        font-weight: 600;
        font-size: 20px;
        filter: blur(0.4px);

        @media all and (max-width: 767px) {
            font-weight: 15px;
        }
    }
    
}