#sliding-popup.sliding-popup-bottom {
    font-size: 15px;
    line-height: 20px;

    .popup-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media all and (max-width: $screen-md){
            max-width: 90%;
        }
    }

    #popup-text {
        padding: 10px 5px;
        flex-basis: 100%;
        max-width: 100%;

        button {
            font-size: 12px;
        }
    }

    #popup-buttons {

        display: flex;
        justify-content: right;
        flex-grow: 2;
        max-width: 100%;
        padding: 10px 5px;

        @media all and (max-width: $screen-xs){
            flex-basis: 100%;
            max-width: 100%;
            justify-content: center;
        }

        button {
            color: #005FA0 !important;
            background-color: #fff;

            font-family: $fontGlobal;
            font-size: 22px;
            font-weight: 600;

            padding: 10px;
            border-radius: 0;
            border-style: none;
            border-color: #005FA0;

            &.decline-button {
                background-image: none;
                box-shadow: none;
            }

            &.agree-button {
                margin-right: 0.75rem;
            }

            @media all and (max-width: $screen-xs){
                margin-top: 0.25rem;
            }
        }
    }
    .eu-cookie-compliance-categories {
        flex-grow: 1;
        padding: 10px 5px;
        font-size: 18px;
        line-height: 23px;

        @media all and (max-width: $screen-xs){
            flex-basis: 100%;
            max-width: 100%;
        }       
    }
}