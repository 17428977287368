.vdr-icon-search{
  @include vdr-icon(25, '../img/Search.svg');
  @media (min-width: 768px) and (max-width:991px){
    @include vdr-icon(20, '../img/Search.svg');
  }
}

.vdr-icon-phone{
  @include vdr-icon(30, '../img/Phone.svg');
}

.vdr-icon-mobil{
  @include vdr-icon(30, '../img/Mobil.svg');
}

.vdr-icon-mail{
  @include vdr-icon(30, '../img/Mail.svg');
}

.vdr-icon-download{
  @include vdr-icon(32, '../img/Download.svg');
  @include breakpoint($screen-md-min){
    @include vdr-icon(50, '../img/Download.svg');
  }
}

.vdr-icon-link-download{
  @include vdr-link-icon(32, '../img/Download.svg');
  @include breakpoint($screen-md-min){
    @include vdr-link-icon(50, '../img/Download.svg');
  }
}

.vdr-icon-link{
  @include vdr-icon(32, '../img/Link.svg');
  @include breakpoint($screen-md-min){
    @include vdr-icon(50, '../img/Link.svg');
  }
}

.vdr-icon-share-mail{
  @include vdr-icon(45, '../img/Mail.svg');
}

.vdr-icon-share-facebook{
  @include vdr-icon(45, '../img/Facebook.svg');
}

.vdr-icon-share-linkedin{
  @include vdr-icon(45, '../img/LinkedIn.svg');
}

.vdr-icon-share-linkedin-negative{
  @include vdr-icon(43, '../img/LinkedIn-negativ.svg');
}

.vdr-icon-share-x-negative {
  @include vdr-icon(43, '../img/X-Icon-negativ.svg');
}

.vdr-icon-share-twitter{
  @include vdr-icon(45, '../img/X-Icon-positiv.svg');
}

.vdr-icon-more1{
  @include vdr-icon(40, '../img/more1.svg');
}

.vdr-icon-more2{
  @include vdr-icon(40, '../img/more2.svg');
}

.vdr-icon-more3{
  @include vdr-icon(40, '../img/more3.svg');
}

.vdr-icon-more4{
  @include vdr-icon(40, '../img/more4.svg');
}

.vdr-icon-more5{
  @include vdr-icon(40, '../img/more5.svg');
}

.vdr-icon-more6{
  @include vdr-icon(40, '../img/more6.svg');
}

.vdr-icon-share-insta {
  @include vdr-icon(45, '../img/Instagram-Icon-positiv.svg');
}

.vdr-icon-share-insta-negative {
  @include vdr-icon(43, '../img/Instagram-Icon-negativ.svg');
}
