.navbar-header {
  //float: right;
  img {
    max-height: 23px;
    width: 100px;
    @include breakpoint($screen-md-min){
      width: 130px;
      max-height: 30px;
      max-width: 272px;
    }
  }
  margin-left: -10px !important;
  @include breakpoint(max-width $screen-sm-max){
    float: none;

  }
  @include breakpoint($screen-md-min){
    margin-right: -20px !important;
  }
}
.navbar .logo {
  padding-right: 0;
  margin-bottom: 0;
  margin-top: 17px;
  -webkit-transition: margin-top 0.5s; /* Safari */
  transition: margin-top 0.5s;
  @include breakpoint(max-width $screen-sm-max){
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
body.front{
  .navbar .logo {
    margin-top: 10px;
    padding-top: 3px;
    @include breakpoint($screen-sm){
      padding-top: 0;
    }
    @include breakpoint($screen-md-min){
      margin-top: 35px;
    }
  }
  &.front-down{
    .navbar .logo {
      margin-top: 10px;
      @include breakpoint($screen-sm){
      }
      @include breakpoint($screen-md-min){
        margin-top: 17px;
      }
    }
  }
}
.navbar-collapse {
  padding:0;
}


#navbar {
  top: 0;
  width: 100%;
  max-width: none;
  margin: 0;
  z-index: 10;
  background: $colBG;
  border: 0;
  height: $heightNavbar;

  @include breakpoint(max-width $screen-sm-max){
    height: 50px !important;
  }

  .navbar-toggle{
    border: none;
    margin-right: 10px;
    &:hover, &:focus, &:active{
      background-color: transparent;
    }
    .icon-bar{
      width: 29px;
      height: 3px;
      background-color: $vdr-font-blue;
    }
    .icon-bar + .icon-bar{
      margin-top: 5px;
    }
  }
}
.navbar.container > .container {
  margin-left: auto;
  margin-right: auto;
  width: inherit;
}

#language_switcher, #mm-language_switcher{
  margin-right:25px;
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;
  ul{
    list-style-type: none;
    li{
      display: inline-block;
      a, span{
        color: $bg-gradient-grey-dark;
        font-size: 19px;
        font-weight: 600;
        display: block;
        transition: padding 0.5s ease 0s;
        padding-top: 20px;
        &:hover {
          color: $colFourth;
        }
        &.locale-untranslated{
          text-decoration: none;
        }
      }
      &.active{
        a.active{
          color: $vdr-font-blue;
          &:hover {
            color: $colFourth;
          }
        }
      }
      > a:after{
        content: "|";
        display: inline-block;
        color: $vdr-font-blue;
      }
      &:last-child a:after{
        display: none;
      }
    }
  }
}
body.front #language_switcher{
  ul{
    li{
      height: 100px;
      a{
        padding-top: 36px;
      }
    }
  }
}

body.front.front-down #language_switcher{
  ul{
    li{
      height: 65px;
      a{
        padding-top: 20px;
      }
    }
  }
}

#bootstrap_large_menu {
  font-family: $fontHeadlines;

  #language_switcher{
    display: inline-block;
  }
}
/*#bootstrap_large_menu {


}*/

#bootstrap_large_menu > ul.menu.nav {
  margin:0 auto;
  font-size: $sizeMenu;

}
/* Menu 2. Ebene */
#navbar_full_menu {
  color: $colFont;
  .menu.nav {
    /* Menu 3. Ebene */
    font-size: $sizeSubmenu;
    line-height: 30px;
    font-family: $fontGlobal;
    color: $colFifth;
    @media (min-width: 768px) and (max-width:991px){
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nav {
      /* Menu 4. Ebene */
      font-weight: 300;
      color: $colFont;
      .menu.nav {
        /* Menu 5. Ebene */
        padding-left:0px;
        .menu.nav {
          /* Menu 5. Ebene */
          padding-left:10px;
        }
      }
    }
  }
}
#navbar_full_menu {
  .container {
    position: relative;

    #full_menu_close_button{
      height: 28px;
      width: 28px;
      position: absolute;
      top: 20px;
      right: 0px;
      text-align: center;
      display: none;
      &:before{
        vertical-align: middle;
      }
    }
  }
}
#bootstrap_large_menu span.nolink, #bootstrap_large_menu a.nolink, #bootstrap_large_menu > ul > li.level-1 > a, #bootstrap_large_menu button.nolink{
  cursor: pointer;
  color: $vdr-font-blue;
  font-weight: 600;
  font-size: 18px;
  padding: 20px 0 2px 0;
  margin: 0 12.5px;
  outline: none;
  border-bottom: 2px solid transparent;
  transition: border-color 0.5s;

  @media only screen and (min-width: 985px) and (max-width: 1200px) {
    font-size: 15px;
  }

  &:hover, &.active, &:active, &:focus {
    border-bottom: 2px solid $vdr-font-blue;
    text-decoration: none;
    outline: none;
  }
}
#bootstrap_large_menu button.nolink{
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
body.front #bootstrap_large_menu span.nolink, body.front #bootstrap_large_menu a.nolink, body.front #bootstrap_large_menu > ul > li.level-1 > a, body.front #bootstrap_large_menu button.nolink{
  padding: 36px 0 2px 0;
  -webkit-transition: padding-top 0.5s, border-color 0.5s; /* Safari */
  transition: padding-top 0.5s, border-color 0.5s;
}
body.front.front-down #bootstrap_large_menu span.nolink, body.front.front-down #bootstrap_large_menu a.nolink, body.front.front-down #bootstrap_large_menu > ul > li.level-1 > a, body.front.front-down #bootstrap_large_menu button.nolink{
  padding: 20px 0 2px 0;
}
#bootstrap_large_menu .nav > li.special_menu_vdr_intern > a{
  color: $vdr-font-blue;
  padding: 20px 0 2px 0;
  transition: all 0.5s ease 0s;
  text-decoration: none;
  margin: 0 12.5px;
  display: inline;
  &:hover {
    border-bottom: 1px solid $vdr-font-blue;
  }
}
#navbar_full_menu span {
  line-height: normal !important;
}

@media (min-width: 992px) and (max-width:1199px){
  #bootstrap_large_menu span.nolink, #bootstrap_large_menu a.nolink, #bootstrap_large_menu > ul > li.level-1 > a, #bootstrap_large_men .nav > li.special_menu_vdr_intern > a {
    margin: 0 10px 0 0;
  }
  #language_switcher{
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (max-width:991px){
  #bootstrap_large_menu span.nolink, #bootstrap_large_menu a.nolink, #bootstrap_large_menu > ul > li.level-1 > a, #bootstrap_large_menu .nav > li.special_menu_vdr_intern > a {
    margin: 0 5px;
    font-size: 14px;
    padding: 20px 0 0 0;
  }
  #language_switcher{
    margin-right: 10px;
    font-size: 14px;
  }
}
@media (max-width:991px){
  .domain-vdr-web.logged-in,
  .domain-vme-web.logged-in,
  .domain-akb-web.logged-in{
    #bootstrap_large_menu span {
      margin-right:3px !important;
    }
  }
}

/* on large screens, the main menu must be fixed */
@media all and (min-width: $screen-sm-min) {
  #navbar {
    position: -webkit-sticky;
    position: sticky;

  }
  /* hack for admin menu */
  body.admin-menu,
  body.html {
    /*margin-top: $heightNavbar !important;*/
  }
  body.html.front {
    margin-top: 0px !important;
  }
}

@media (max-width: $screen-sm-max){
  html.mm-opening {
    .mm-opened {
      z-index: 1;
      #navbar{
        position: relative;
      }
    }
  }
}
/* hack for admin menu on large screens */
@media all and (min-width: $screen-sm-min) {
  body.admin-menu #navbar{
    margin-top: 20px !important;
  }
}

//TODO replace standardseite by startseite once we got the respective content type !
body.front{
  #navbar {
    height: $heightNavbarFront;
    -webkit-transition: height 0.5s; /* Safari */
    transition: height 0.5s;
  }
  &.front-down{
    #navbar {
      height: $heightNavbar;
    }
  }
}

body.node-type-standardseite,
body.not-front {
  #navbar {
    position: relative;
    width: 100%;
    max-width: none;
    margin: 0;
    height: $heightNavbar;
    z-index: 10;
    border-bottom: 0;
    background: $colBG;

    @media all and (min-width: $screen-md-min) {
      position: fixed;
    }
  }
}

#bootstrap_large_menu{
  /*background: $colBG;*/

  & > ul {
    display: table;

    & > li{
      display: inline-block;
      vertical-align: middle;
      height: $heightNavbar;
      & > span{
        display: block;
      }
    }
  }
}
body.front {
  #bootstrap_large_menu > ul > li{
    height: $heightNavbarFront;
  }
  &.front-down{
    #bootstrap_large_menu > ul > li{
      height: $heightNavbar;
    }
  }
}

#search_form,
#navbar_full_menu{
  display: none;
}
#bootstrap_large_menu.show_menu{
  #navbar_full_menu {
    display: block;
  }
}
#bootstrap_large_menu.show_search{
  #search_form {
    display: block;
    background: rgba(255, 255, 255, 0.9);
  }
}
#search_form,
#navbar_full_menu {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  left: 0px;
  right: 0px;

  & > ul {
    display: table-cell;
  }
}
.bottomup #navbar_full_menu,
.bottomup #search_form{
  bottom: $heightNavbar;
  margin-bottom:-1px;
}
.topdown #navbar_full_menu,
.topdown #search_form{
  top: $heightNavbar;
  transition: top 0.5s;
  margin-top:-1px;
}
body.front .topdown {
  #navbar_full_menu, #search_form {
    top: $heightNavbarFront - 20px;
  }
}

body.front.front-down .topdown {
  #navbar_full_menu, #search_form{
    top: $heightNavbar;
  }
}
#bootstrap_side_menu{
  display: none;
}

.bottomup .navbar-submenu > li {
  vertical-align: bottom;
}
.topdown .navbar-submenu > li {
  vertical-align: top;
  width: 100%;
}

#search_loupe{
  height: 25px;
  width: 25px;
  display: inline-block;
  cursor: pointer;
  padding-top: 22px;
  transition: padding 0.5s;
  @media (min-width: 768px) and (max-width:991px){
    height: 20px;
    width: 20px;
    padding-top: 22px;
  }
}

body.front #search_loupe{
  padding-top: 38px;
  @media (min-width: 768px) and (max-width:991px){
    padding-top: 40px;
  }
}

body.front.front-down #search_loupe{
  padding-top: 20px;
  @media (min-width: 768px) and (max-width:991px){
    padding-top: 22px;
  }
}

.search_arrow{
  background: url("../img/search_arrow.png") no-repeat;
  height: 14px;
  width: 9px;
  display: inline-block;
}
.search_text{
  text-transform: uppercase;
}
#search_form{
  display: none;
  .input-group-btn{
    display: none;
  }
  input::placeholder, input:-moz-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input::-webkit-input-placeholder {
    color: $vdr-font-blue !important;
    text-align: center !important;
  }
  .search-form .form-wrapper .input-group .form-control.form-text{
    padding: 3px 12px 3px 12px;
    height: 36px;
    background-color: $bg-gradient-grey-dark;
    color: $vdr-font-blue;
    font-family: $fontGlobal;
    &::placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
      color: $vdr-font-blue;
      text-align: center;
    }
    @media all and (min-width: $screen-md-min) {
      width: 280px;
    }
  }
}
.show_search #search_form{
  display: block;
}
#search_form {
  .search_arrow,
  .form-wrapper.form-group,
  .search_text{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
  .search-form .form-wrapper{
    margin: 10px 0 24px 20px;
  }
  .search-form{
    text-align: right;
  }
}

/* hack for admins; necessary when the sidebar menu is fixed */
body.admin-menu #mm-bootstrap_side_menu{
  margin-top: 20px !important;
}

.special_menu_vdr_uberuns,
.special_menu_wveb_uberuns,
.special_menu_vme_uberuns,
.special_menu_awb_uberuns,
.special_menu_akb_uberuns,
.special_menu_waz_uberuns{
  & > li{
    width: 100%;

    & > .nav {
      display: inline-block;
      vertical-align: top;
    }
  }

  #special_menu_vdr_uberuns_left,
  #special_menu_wveb_uberuns_left,
  #special_menu_vme_uberuns_left,
  #special_menu_awb_uberuns_left,
  #special_menu_akb_uberuns_left,
  #special_menu_waz_uberuns_left{
    max-width: 25%;
    width:auto;
  }

  #special_menu_vdr_uberuns_center,
  #special_menu_wveb_uberuns_center,
  #special_menu_vme_uberuns_center,
  #special_menu_awb_uberuns_center,
  #special_menu_akb_uberuns_center{
    min-width:25%;
    max-width: 47%;
    width:auto;
    margin-left: 20px;
    margin-right: 20px;
    .nav{
      display: inline-block;
      vertical-align: top;
      width:auto;
      max-width: 47%;
      &:first-child {
        /*margin-right:7px;
        margin-left:-10px;*/
      }
    }

    .nav.special_menu_vdr_uberuns_center_title,
    .nav.special_menu_wveb_uberuns_center_title,
    .nav.special_menu_vme_uberuns_center_title,
    .nav.special_menu_awb_uberuns_center_title,
    .nav.special_menu_akb_uberuns_center_title{
      width: 100%;
      display:inline;
      margin-left:0;
    }
    span.special_menu_akb_uberuns_center_content,
    span.special_menu_awb_uberuns_center_content,
    span.special_menu_vme_uberuns_center_content,
    span.special_menu_vdr_uberuns_center_content,
    span.special_menu_wveb_uberuns_center_content{
      margin:0;
      > .nav {
        float:right;
        &:first-child {
          margin-right:2%;
          float:left;
        }
        .level-3 {
          .nav {
            max-width:100% !important;
          }
        }
      }
    }
  }
  #special_menu_waz_uberuns_center{
    min-width:auto;
    max-width: auto;
    width:auto;
    margin-left: 20px;
    margin-right: 20px;
    .nav{
      display: inline-block;
      vertical-align: top;
      width:auto;
      max-width: auto;
    }
    .nav.special_menu_waz_uberuns_center_title{
      width: 100%;
      display:inline;
      margin-left:0;
    }
    span.special_menu_waz_uberuns_center_content{
      margin:0;
      > .nav {
        float:right;
        &:first-child {
          margin-right:2%;
          float:left;
        }
        .level-3 {
          .nav {
            max-width:100% !important;
          }
        }
      }
    }
  }

  #special_menu_awb_uberuns_center{
    min-width:30%;
    max-width: 47%;
    width:auto;
    margin-left: 20px;
    margin-right: 20px;
    .nav{
      max-width: 47%;
    }
  }

  #special_menu_akb_uberuns_center,
  #special_menu_wveb_uberuns_center{
    min-width:25%;
    max-width: 47%;
    width:auto;
    margin-left: 20px;
    margin-right: 20px;
    .nav{
      max-width: 100%;
    }
  }

  #special_menu_vme_uberuns_center{
    min-width:45%;
    max-width: 50%;
  }

  #special_menu_vdr_uberuns_right,
  #special_menu_wveb_uberuns_right,
  #special_menu_vme_uberuns_right,
  #special_menu_awb_uberuns_right,
  #special_menu_akb_uberuns_right,
  #special_menu_waz_uberuns_right{
    max-width: 25%;
    width:auto;
  }
}

.special_menu_vdr_unserethemen,
.special_menu_vdr_newsroom,
.special_menu_vdr_netzwerk,
.special_menu_vdr_service,
.special_menu_vdr_intern,

.special_menu_wveb_unserethemen,
.special_menu_wveb_newsroom,
.special_menu_wveb_netzwerk,
.special_menu_wveb_service,
.special_menu_wveb_intern,

.special_menu_vme_unserethemen,
.special_menu_vme_newsroom,
.special_menu_vme_netzwerk,
.special_menu_vme_service,
.special_menu_vme_intern,

.special_menu_awb_unserethemen,
.special_menu_awb_newsroom,
.special_menu_awb_netzwerk,
.special_menu_awb_service,
.special_menu_awb_intern,

.special_menu_akb_unserethemen,
.special_menu_akb_newsroom,
.special_menu_akb_netzwerk,
.special_menu_akb_service,
.special_menu_akb_intern,

.special_menu_waz_kontakt,
.special_menu_waz_newsroom,
.special_menu_waz_netzwerk,
.special_menu_waz_service,
.special_menu_waz_intern{
  & > li{
    width: 100%;

    & > .nav {
      display: inline-block;
      vertical-align: top;
    }
  }
}
.special_menu_vdr_unserethemen {
  > li > ul.nav {
    max-width:22%;
  }
}
.special_menu_vme_unserethemen {
  > li > ul.nav {
    max-width:14%;
  }
}
.special_menu_akb_unserethemen > li > ul.nav {
  max-width: 18%;
}
.special_menu_awb_unserethemen > li > ul.nav {
  max-width: 25%;
}
.level2 > ul.nav {padding-left:0;}

.nav.navbar-submenu > li {
  display: inline-block;
  > a {
    display: block;
    padding: 0;
    position: relative;
    color: $vdr-font-blue;
    padding: 0;
    border-bottom: 0;
  }
}
.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: underline;
  background-color: transparent;
}
.special_menu_vdr_uberuns .nav .nav .nav {
  /*margin-right: 20px;*/
}
.nav > li > .nav > li > a {
  padding: 0;
}

#special_menu_vdr_unserethemen_two,
#special_menu_vdr_newsroom_two,
#special_menu_vdr_netzwerk_two,
#special_menu_vdr_service_two,
#special_menu_vdr_intern_two,

#special_menu_wveb_unserethemen_two,
#special_menu_wveb_newsroom_two,
#special_menu_wveb_netzwerk_two,
#special_menu_wveb_service_two,
#special_menu_wveb_intern_two,

#special_menu_vme_unserethemen_two,
#special_menu_vme_newsroom_two,
#special_menu_vme_netzwerk_two,
#special_menu_vme_service_two,
#special_menu_vme_intern_two,

#special_menu_awb_unserethemen_two,
#special_menu_awb_newsroom_two,
#special_menu_awb_netzwerk_two,
#special_menu_awb_service_two,
#special_menu_awb_intern_two,

#special_menu_akb_unserethemen_two,
#special_menu_akb_newsroom_two,
#special_menu_akb_netzwerk_two,
#special_menu_akb_service_two,
#special_menu_akb_intern_two,

#special_menu_waz_kontakt_two,
#special_menu_waz_newsroom_two,
#special_menu_waz_netzwerk_two,
#special_menu_waz_service_two,
#special_menu_waz_intern_two {
  margin: 0 20px;
}


#special_menu_vdr_unserethemen_three,
#special_menu_vdr_newsroom_three,
#special_menu_vdr_netzwerk_three,
#special_menu_vdr_service_three,
#special_menu_vdr_service_four,

#special_menu_wveb_unserethemen_three,
#special_menu_wveb_newsroom_three,
#special_menu_wveb_netzwerk_three,
#special_menu_wveb_service_three,
#special_menu_wveb_service_four,

#special_menu_vme_unserethemen_three,
#special_menu_vme_newsroom_three,
#special_menu_vme_netzwerk_three,
#special_menu_vme_service_three,
#special_menu_vme_service_four,

#special_menu_awb_unserethemen_three,
#special_menu_awb_newsroom_three,
#special_menu_awb_netzwerk_three,
#special_menu_awb_service_three,
#special_menu_awb_service_four,

#special_menu_akb_unserethemen_three,
#special_menu_akb_newsroom_three,
#special_menu_akb_netzwerk_three,
#special_menu_akb_service_three,
#special_menu_akb_service_four,

#special_menu_waz_unserethemen_three,
#special_menu_waz_newsroom_three,
#special_menu_waz_netzwerk_three,
#special_menu_waz_service_three,
#special_menu_waz_service_four {
  margin-right: 20px;
}
#special_menu_vdr_newsroom_four,
#special_menu_vme_newsroom_four,
#special_menu_vme_unserethemen_four,
#special_menu_vme_unserethemen_five,
#special_menu_akb_unserethemen_four {
  margin-right: 20px;
}

//on desktop, we handle parts of the sticky menu via css
@media all and (min-width: $screen-sm-min) {
  body.sticky_menu_header_on_top{
    header#navbar{
      top: 0 !important;
      bottom: auto !important;
    }
    div.main-container.container {
      top: 0 !important;
      bottom: auto !important;
      margin-top: 0 !important;
      position: relative !important;
    }
    #mm-0{
      top: 0 !important;
      background-color: rgba(212, 217, 224, 1.0) !important;
    }
  }
  body.sticky_menu_header_on_offset{
    header#navbar{
      top: auto !important;
    }
    div.main-container.container {
      top: auto !important;
      bottom: -20px !important;
      margin-top: 0 !important;
      position: relative !important;
    }
    #mm-0{
      top: 0 !important;
    }
  }
}

//fixed tablet front page: min-width 768 + touchevents
@media all and (min-width: 768px) {
  html.touchevents{
    header#navbar{
      top: 0 !important;
    }
  }
}


.not-front {
  header#page-header {
    @media (min-width:768px) {
      left: 0;
    }
    position: fixed;
    width:100%;

    @include breakpoint($screen-sm) {
      top: 0;
    }

  }
  #page-header + div.row {
    margin-top:100px;
    @media (min-width:768px){
      margin-top: 130px;
    }
    @media (min-width:992px){
      margin-top: 250px;
    }
  }
}

.header-gradient-container{
  position: absolute;
  height: 20px;
  margin-top: -20px;
  overflow: hidden;
  display: block;
  width: 100%;
  @include breakpoint($screen-sm-min){
    height: 70px;
    margin-top: -70px;
  }
}
.header-gradient {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  transform: scale(1.0001);
}
.header-gradient-cyan {
  margin-top: -20px;
  border-width: 20px 100vw 0 0;
  border-color: transparent #005FA0 transparent transparent;
  @include breakpoint($screen-sm-min){
    margin-top: -32px;
    border-width: 32px 100vw 0 0;
  }

  @include breakpoint($screen-md-min){
    margin-top: -70px;
    border-width: 70px 100vw 0 0;
  }
}

.header-gradient-grey {
  margin-top: -15px;
  border-width: 15px 100vw 0 0;
  border-color: transparent #DCE9F2 transparent transparent;
  z-index: 1;
  @include breakpoint($screen-sm-min){
    margin-top: -25px;
    border-width: 25px 100vw 0 0;
  }

  @include breakpoint($screen-md-min){
    margin-top: -60px;
    border-width: 60px 100vw 0 0;
  }
}

#header_title{
  position: fixed;
  width: 100%;
  top: 165px;
  @include breakpoint($screen-sm){
    top: 220px;
  }
  @include breakpoint($screen-md-min){
    top: 220px;
  }
  h1{
    color: #fff;
    display: inline;
    font-size: 32px;
    line-height: 48px;
    padding: 5px 20px 5px 20px;
    @include breakpoint($screen-md-min){
      font-size:65px;
      line-height:98px;
    }
    @include slider-headline-bg-gradient;
    a{
      color: #fff;
      font-size: 20px;
      line-height: 33px;
      @include breakpoint($screen-sm-min){
        font-size:45px;
        line-height:78px;
      }
      @include breakpoint($screen-md-min){
        font-size:65px;
        line-height:98px;
      }
    }
  }

}

.not-front #header_title{
  h1{
    a{
      @include breakpoint(max-width $screen-sm-max){
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
}

.custom_title {
  display: inline;
  font-family: "Titillium Web";
  font-weight: 700;
  padding: 5px 20px 5px 20px;
  background: rgba(0, 95, 160, 0.36);
  opacity: 1;
  font-size: 20px;
  line-height: 31px;
  color: #fff;
  @include breakpoint($screen-xs){
    font-size: 20px;
    line-height: 40px;
    padding: 3px 5px 3px 5px;
  }
  @include breakpoint($screen-sm){
    font-size: 35px;
    line-height: 50px;
  }
  @include breakpoint($screen-md-min){
    font-size: 55px;
    line-height: 98px;
  }

  * {
    color: #fff;
    font-weight: 700;

    &:hover, &:focus {
      color: #fff;
      text-decoration: none;
    }
  }

}

header#page-header {
  position: fixed;
  top: 0;
  article {
    left: 0;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%;
    height: 750px;
    z-index: 0;

    @include breakpoint(max-width $screen-sm-max){
      height: 550px;
    }

    #block-views-vdr-startseite-video-block {
      h2 {
        display: none;
      }
    }

    header {
      display: none;
    }

    video {

      .front & {
        transform: scale(250%) translateY(25%);

        @include breakpoint($screen-xs) {
          transform: scale(200%) translateY(25%);
        }
        @include breakpoint($screen-md) {
          transform: none;
        }
      }
    }
  }
}
