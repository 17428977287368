html, body {
	width:100%;
	min-height:100%;
	min-width:100%;
	max-width:100%;
	margin:0;

}
body{
	font-family: $fontGlobal;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-weight: $fontRegular;
	color: $colFont;
	background-color: #B4B4B4;

	font-size: $sizeMobile;
	line-height: 20px;
	padding-top: 0;
	@include breakpoint($screen-sm-min){
		font-size: $sizeStandard;
		line-height: 28px;
	}
	@include breakpoint($screen-md-min){
		padding-top: 65px;
	}
	-webkit-hyphens: manual;
	-moz-hyphens: manual;
	-ms-hyphens: manual;
	hyphens: manual;
}

body.front{
	/*background: url("../img/Testbackground.jpg") repeat fixed 0 0 / cover ;*/
	padding-top:0px;
}

p {
	line-height: 25px;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 0;
    padding-top: 20px;
}
a {
	color:$vdr-font-blue;
	transition: all 0.5s ease 0s;
	font-weight:400;
	&:hover {
		color: $colFourth;
		transition: all 0.5s ease 0s;
		text-decoration: none;
	}
}
#block-system-main .col-md-8,
.node-type-themenuebersichtsseite .anrisstext {
	a {
		color:$vdr-font-blue;
		//font-weight:600;

		&:hover {
			color: $colFourth;
			text-decoration:none;
			transition: all 0.5s ease 0s;
		}
	}
	#gremienStruktur,
	.paragraphs-item-bild,
	.view-beitraege,
	.node-kontaktperson,
	.pagination,
	.file,
	.view-display-id-block_4{
		a{
			color:$colFont;
			//font-weight:inherit;
			&:hover {
				color: $colFourth;
			}
		}
	}

	.socialBtn,
	.block-share-icons{
		a{
			color:$colFont;
			font-weight:inherit;
			&:hover {
				color: $colFifth;
			}
		}
	}
	a.flright{
		color:$colFont;
		font-weight:inherit;
		&:hover {
			color: $colFifth;
		}
	}
}

footer a:hover {
	text-decoration:underline;
}

/*#block-system-main a {
	border-bottom: 3px solid $colFourth;
    box-shadow: 0 -5px 0 $colFourth inset;
}*/

#navbar {
	border-radius:0;
	@include breakpoint(max-width $screen-sm-max){
		height: auto !important;
		position: sticky;
	}
}

.mm-slideout + .mm-opened{
	#navbar{
		position: relative;
	}
}




.views-row,
.field{
  position: relative;
}

#skrollr_placeholder{
	position: absolute;
	top: -20px;
	@media all and (max-width: 1024px) {
	 width: 100%;
	 height: 3px;
	 background: red;
	 z-index: 1999;
	}
}
.main-container-background{
	// @include bg-gradient;
	background-color: #DCE9F2 ;
	margin-top: 215px;
	z-index: 1;
	position: relative;
	@include breakpoint($screen-sm-min){
		margin-top: 335px;
	}

}
.node-type-startseite{
	.main-container-background{
		margin-top: 40vh;
		@include breakpoint($screen-xs){
			margin-top: 45vh;
		}
		@include breakpoint($screen-md){
			margin-top: 38%;
		}

	}
}
#mm-0.mm-page.mm-slideout {
	//background-color: rgba(212,217,224,1);
	@include bg-gradient;
}

.front {
	#mm-0.mm-page.mm-slideout {
		/*top:460px;*/
/* 		background-color: rgba(0,0,0,0); */
		/*padding-top:50px;
		position: absolute;*/
		width:100%;
	}
	.block:first-child h2.block-title {
		margin-top: 15px;
	}
	.region.region-header {
    //position: absolute;
    z-index: -1;
	}
	/*#navbar {
		position:absolute;
		top:-80px;
	}*/
	.field-name-bannerbild > div {
		background-size: cover;
		background-repeat: no-repeat;
		@media (max-width:767px) {
			background-size: 100% auto;
		}
	}

	.footer.container {
		position:relative;
	}
	section.element-frontpage {
		padding: 0 !important;
	}

	#block-views-beitragsart-block {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (max-width:767px)  {
	.front {
		.region.region-header {
			position: static;
			z-index: -1;
		}
		#flexslider-2 .slides li {
			min-height:0;
		}
	}
}

/*
section{
  background: $colBG;
}
#block-system-main > div > .row > div {
	background: $colBG;
}
*/
.nav-tabs {
    border-bottom: medium none;
}
.nav-tabs > li {
	> a {
		border-color: $colBorder1 $colBorder1 $colBorder2;
		background-color: $colBorder1;
	}
	> a:hover {
		border-color: $colBorder1 $colBorder1 $colBorder2;
	}
}

.region-content #block-system-main .col-md-8 section,
section#block-views-kontaktpersonen-block-1 {
	background: $colBG;
	margin-top: 20px;
	/*margin-left:-20px;*/
	padding:20px;
	&:first-child {
		margin-top:0;
		padding-top:0;
	}
}

.node-kontaktperson {
	.field-name-field-foto {
		margin: 0 0 20px 0;
		text-align: left;
		float: none !important;

		.img-responsive {
			display: inline-block;
		}
	}
	&.view-mode-zweispaltig {
		.field-name-field-foto {
			@include breakpoint($screen-sm){
				text-align: center;
				float: left !important;
				margin: 5px 20px 40px 0;
			}
		}
		.field-label-inline.inline {
			display: inline;
		}
		.field-name-field-email.field-label-inline.inline {
			display: block;
		}
	}
}
/*section#block-views-kontaktpersonen-block-1 {
	.field-name-field-foto {
		float: left;
		margin: 5px 20px 20px 0;
	}
	.field-label-inline.inline {
		display: inline-block;
	}
}*/

#block-system-main > div > .row {
	> div:first-child > div, > div:first-child > form.webform-client-form {
		/*margin-left: -20px;*/
		+ div {
			padding-top:0;
		}
		&.field-name-title {
		}
	}
	> div.col-md-4:nth-child(2) {
		/*background: $colBG;*/
	}
	.col-md-4 > div,
	.col-md-4 > h3{
		background-color: $colBG;
		margin: 0;
		@media (min-width:992px){
			margin: 0 0 0 -20px;
		}
		padding: 1px 20px 20px;
	}
	.col-md-4 > h3{
		padding: 1px 20px 10px;
	}
	> .col-sm-12 {
		/*padding-right: 0;*/
	}
	> .col-md-4 {
		@media (max-width:991px){
			margin-top:20px;
		}
	}
}

h1, h2, h3, h4, h5, h6{
  font-family: $fontHeadlines;
	color: $vdr-font-blue;
}
h1, .h1 {
	font-size: 30px;
	font-weight: 600;
	line-height: 35px;
	@include breakpoint($screen-md-min){
		font-size: 38px;
		line-height: 46px;
		padding: 15px 0;
		margin:0;
	}


}
h1 .block-share-icons {
    float: right;
    font-size: 18px;
	a:hover {
		color: #5082be;
	}
	@media (max-width:767px) {
		float:left;
		text-align:center;
		display:block;
		&:before {
			content:"Folgen Sie @VDRberlin bei Twitter oder Facebook";
			display:block;
			text-align:left;
			clear:both;
			margin-top:20px;
			font-family: 'Source Sans Pro' !important;
			font-weight:300 !important;
		}
	}
}
h2, .h2 {
	font-size: 30px;
	font-weight: 600;
	line-height: 36px;
	padding-bottom:8px;
	margin-bottom:20px;
	text-align: center;

	@include breakpoint($screen-md-min){
		font-size:38px;
		line-height: 46px;
	}
}

h3 {
	border-bottom:none !important;
}
h2 + h3 {
    margin-top: 0;
    padding-top: 0;
}
h3 + iframe {
    margin-top: 15px !important;
}
@media (max-width:767px) {
	section .col-lg-6 {
		> h3,
		> iframe {
			display: none !important;
		}
	}
}


.breadcrumb {
	display: none;
  border-radius: 0;
  list-style: outside none none;
	margin: 15px 0;
	font-family: $fontHeadlines;
	font-size: 15px;
	background-color: transparent;
	padding: 8px 0;
	li {
		color: $vdr-cyan;
		.active {
			color: $vdr-cyan;
		}
		a {
			color: $vdr-cyan;
		}
	}
	> li.active {
		color: $vdr-cyan;
	}
	> li + li:before {
		color: $vdr-cyan;
		content: "/ ";
		padding: 0 0px;
	}
	@include breakpoint($screen-md-min){
		display: block;
	}
}


.anrisstext,
.subheadline {
	font-weight:700;
	font-family: $fontHeadlines;
	font-weight:700;
	padding: 15px 0;
	li {
		margin-left: 20px;
	}
}
.field-name-field-bildbeschreibung-copyright {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
	margin: 9px 0 16px;
}

.node-kontaktperson {
	color: $vdr-font-blue;
	font-size: 18px;
	a {
		color: $vdr-font-blue;
		&:hover {color:$colFourth;}
	}
	.group-kontaktdaten{
		display: inline-block;
	}
	.field-name-field-stellenbezeichnung {
		margin-bottom: 10px;
	}
	.field-name-field-kontaktperson-titel {
		font-weight:700;
		font-family: $fontHeadlines;
		display: inline-block;
		float: left;
		&:after {
			content: " ";
			display: inline-block;
		}
	}
	.field-name-field-vorname {
		font-weight:700;
		font-size: 18px;
		display: inline-block;
		float: left;
		&:after {
			content: " ";
			display: inline-block;
		}
	}
	.field-name-field-name {
		font-weight:700;
		font-size: 18px;
		display: inline-block;
		&:after {
			display:block;
			content: " ";
		}
	}

	.field-label {
		font-weight:300;
		&:before {
			content: " ";
			display: block;
		}
	}
	.field-name-field-telefonnummer {
		display: inline-block;
	}
	.field-name-field-email,
	.field-name-field-kontaktperson-email	{
		display: inline-block;
		.field-label {
			float: left;
		}
	}
	.field-name-field-vcard, .field-name-cf-vcard-kontaktperson {
		margin-top:17px;
	}
	.field-name-field-foto .img-responsive {
		height: 165px;
		width: 165px;
	}
}

.node-standardseite {
	.paragraphs-items-field-element{
		margin-bottom: 60px;
		.field-name-field-element{
			.paragraphs-item-bild{
				margin-top: 30px;

				.field-name-field-anrisstext{
					margin-bottom: 15px;
				}
			}


		}
	}
	.col-md-8 {
		.field-type-paragraphs:first-child {
			.entity {
				.field-name-field-foto:last-child,
				.field-name-field-foto + .field-name-field-anrisstext {
					margin-bottom: 20px;
				}
			}
		}
	}

	.paragraphs-item-listen-element {
		padding-bottom: 32.5px;
		margin-bottom: 32.5px;
		border-bottom: 1px solid #005FA0;
		margin-top: 20px;
		padding-right: 70px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:first-of-type {
			margin-top: 40px;
		}

		.field-name-field-foto {

		}

		.field-name-field-text {
			flex-grow: 1;
			flex-shrink: 1;
			padding-left: 40px;

			strong {
				font-weight: normal;
				font-size: 30px;
				line-height: 36px;
				color: #005FA0;
				margin: 10px 0;
				font-family: "Titillium Web", sans-serif;
			}
		}

		@media all and (max-width:767px) {

			padding-right: 0;
			flex-direction: column;


			.field-name-field-text {
				margin-top: 1rem;
				padding-left: 0;

				width: 100%;
				max-width: 320px;
			}
		}
	}
}

//no login button for logged in users
body.page-user #loginButton#loginButton{
 display: none;
}

//on mobile, we show the login button at the bottom of its container
#loginButton{
    text-align: center;
    cursor: pointer;
    display: block;
	/*z-index: 10;*/

    @include yellowModal;

    #loginButtonInner{
      font-family: $fontHeadlines;
	  font-weight:700;

      &:hover {
        color:$colBG;
      }
    }
}

//on desktop, the login button is fixed at the bottom of the screen
@media all and (min-width: $screen-sm-min) {
  #loginButton{
    position: fixed;
    bottom: 0;
    width: 160px;
    left: 50%;
    margin-left: -80px;
	font-size: 22px;
	padding: 5px 0;
	z-index: 3;
  }
}

#block-user-login{
    @include yellowModal;
}

//on mobile, the login block is at bottom of its container
@media all and (max-width: $screen-xs-max) {
  #block-user-login{
    display: none;
  }
}

//on large screens, the login block is centered in the middle
@media all and (min-width: $screen-sm-min) {
  #block-user-login{
    display: none;
    position: fixed;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 20% auto;
    min-height: 360px;
    min-width: 300px;
	max-width:700px;
    padding: 30px;
    z-index: 999;

    #login_img {
      position: absolute;
      left: 30px;
      top: 30px;
    }
    h2,
    form {
      margin-left: 130px;
    }
	h2.block-title {
		margin-top: 5px;
		padding-top: 0;
	}

	li {
		font-family: $fontHeadlines;
		font-weight: 700;
		list-style: none;
		a {
			&:before {
				content:"";
				font-family: fontAwesome;
				margin-right:5px;
			}
			&:hover {
				color:$colBG;
			}
		}
	}

    .form-item label{
      width: 100%;
	  font-size:$sizeStandard;
	  margin-top: 5px;
    }

    button[type=submit] {
      background: transparent;
      border: none;

      @include yellowModal;

      width: 100%;
	  &:hover {
		color:$colBG;
		border-color:$colBG;
	  }
    }
  }
}

input[type=text],
input[type=password] {
  border: none !important;
  background: $colBG;
  border-radius: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: 0;
  transition: 0;
}

.view-beitraege, .view-listen.view-display-id-block_2 {
	.field-name-field-datum {
		/*font-weight: bold;*/
		font-size: 18px;
	}
	h3 {
		border-bottom: none !important;
		margin: 0;
		padding-top:0;
	}
	.views-row {
		margin-top: 20px;
		padding-right: 40px;
		@media (max-width:767px){
			padding-right: 20px;
		}
	}
	.view-footer {
		margin-top: 20px;
		padding-right: 33px;
		text-align:right;
		a {
			font-weight:bold;
			&:after {
				content: "";
				text-align:right;
				font-family: fontAwesome;
				font-size: 36px;
				position: absolute;
				margin-left: 20px;
				font-weight: normal;
			}
		}
	}
}

.view-downloads,
.view-beitragsmodul,
.view-listen {
	.field-name-field-datum {
		/*font-weight: bold;*/
		font-size:18px;
	}
	h3 {
		border-bottom: none !important;
		margin: 0;
		padding-top:0;
	}
	.views-row {
		margin-top: 20px;
		padding-right: 20px;
		border-bottom: 2px solid $colSecond;
		padding-bottom: 20px;
		&.views-row-last {
			border-bottom:none;
			/*padding-bottom: 0;*/
		}
		.node-download,
		.node-beitrag,
		.node-veranstaltung,
		.node-rundschreiben{
			cursor:pointer;
			transition: all 0.5s ease 0s;
			.field-name-field-foto {
				@media(min-width:768px){
					float: left;
				}
				@media(max-width:767px){
					margin-bottom:10px;
				}
				height: 160px;
				margin-right: 20px;
				overflow: hidden;
				width: 160px;
			}
			.col-md-12 > strong,
			.col-md-12 > strong + a,
			.col-md-12 > span.field-label,
			.col-md-12 > .field-name-field-kategorie-schlagwoerter {
				display: none;
			}
			.col-md-12 > .field-name-field-datum,
			.col-md-12 > .field-name-field-ort,
			.col-md-12 > .field-name-field-titel-ort{
				display: inline;
				font-family: $fontHeadlines;
			}
			.col-md-12 > .field-name-field-ort:before,
			.col-md-12 > .field-name-field-titel-ort:before{
				content: "|";
			}
		}
		&:hover {
			color: $colFourth;
			transition: all 0.5s ease 0s;
		}
	}
	.view-footer {
		margin-top: 20px;
		padding-right: 33px;
		text-align:right;
		a {
			font-weight:bold;
			&:after {
				content: "";
				text-align:right;
				font-family: fontAwesome;
				font-size: 36px;
				position: absolute;
				margin-left: 20px;
				font-weight: normal;
			}
		}
	}
}
.col-md-8 {
	.field-name-field-pressedisclaimer + div,
	.field-name-galerie-referenz + div{
		h2 + .view-listen {
			.view-content {
				.views-row {
					clear:both;
					min-height: 180px;
				}
				a {
					display: block;
					min-height: 160px;
				}
			}
			.field-name-field-foto {
				@media (min-width:768px){
					float:left;
				}
				height: 160px;
				margin-right: 20px;
				overflow: hidden;
				width: 160px;
			}
		}
	}
	span.file {
		display:block;
	}
}


//the mobile highlight box is being added to the respective elements markups
.mobile_highlight{
  display: none;
  opacity: 0;
}


#scroll_to_top{
  padding: 10px 15px;
  background: $colMinor;
  color:#b4b4b4;
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 9999;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  display: none;
  border-radius:0;
  font-size:30px;

  div {
    top: 50%;
    margin-top: -25px;
  }
}
/*@media all and (max-width: $screen-xs-max) {*/
@media all and (max-width: 767px) {
  #scroll_to_top{
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
    opacity: 1 !important;
    display: block !important;
    margin: 0 auto !important;
	width:50px;
	bottom: -30px !important;
  }
}

.form-item .form-control,
.form-item .form-control + .input-group-addon {
	background-color: $colInputTxt;
	color:$colFont;
	font-size: 20px;
	box-shadow: none;
	border:1px solid $colInputTxt;
	z-index: 0;
	padding:6px;
	&:hover {
		background-color: #fafafc;
	}
	&.form-select {
		padding:2px 6px;
	}
}

#edit-std-lohn--2,
#edit-m-std--2,
#edit-maximal--2,
#edit-tw-std--2,
#edit-rstd-lohn--2,
#edit-std-lohn,
#edit-m-std,
#edit-maximal,
#edit-tw-std,
#edit-tm-std,
#edit-rstd-lohn,
#edit-std-lohn--2 + .input-group-addon,
#edit-m-std--2 + .input-group-addon,
#edit-maximal--2 + .input-group-addon,
#edit-tw-std--2 + .input-group-addon,
#edit-rstd-lohn--2 + .input-group-addon,
#edit-std-lohn + .input-group-addon,
#edit-m-std + .input-group-addon,
#edit-maximal + .input-group-addon,
#edit-tw-std + .input-group-addon,
#edit-tm-std + .input-group-addon,
#edit-rstd-lohn + .input-group-addon {
    background-color: #fff;
    border: 1px solid #fff;
    width: 100px;
}
#edit-rstd-lohn,
#edit-rstd-lohn--2,
#edit-maximal,
#edit-maximal--2 { display:inline-block; font-weight:bold; font-size:20px; }
.quickcalc-alert { color:#BC5151; }

.node-startseite.view-mode-full{
	> section{
		margin: 60px 0 24px 0;
	}
}

.front .node-startseite .col-md-8 section .field-name-title + .field-name-field-anrisstext {
    margin-top: 15px;
	margin-bottom: 15px;
}

a.more {
	margin-right:20px;
	font-family:$fontHeadlines;
	&:after {
		content: "";
		float: right;
		font-family: fontAwesome;
		font-size: 36px;
		position:absolute;
		right:0;
	}
}

.field-name-galerie .field-name-field-bilder.field-type-paragraphs.field-label-hidden .entity {
    /*cursor: pointer;*/
	margin-bottom: 20px;
    position: relative;
	.field-name-field-kurze-bildbeschreibung {
		font-family: $fontHeadlines;
		font-weight:700;
		font-size:23px;
		padding-bottom: 5px;
		padding-top: 10px;

	}
}
body.node-type-galerie {
	.field-name-galerie .field-name-field-bilder.field-type-paragraphs.field-label-hidden .entity {
		height: 360px;
		.field-name-field-kurze-bildbeschreibung {
			font-family: $fontGlobal;
			font-weight:300;
			height: 120px;
			font-size:20px !important;
			&:after {
				content:none;
			}
			a {
				font-weight:300;
				&:after {
					content: "";
					float: right;
					font-family: fontAwesome;
					font-size: 36px;
				}
			}
		}
	}
	.field-name-field-anrisstext{
		font-family: $fontGlobal !important;
		font-weight:300 !important;
		padding-top:15px !important;
	}
	.field-name-galerie .pagination {
		top: 0px;
	}
	.field-name-title + .field-name-galerie {
		padding-top:15px !important;
	}
}

.field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity > .overlay {
    /*background-color: transparent;
    display: none;*/
	background-color: rgba(80, 130, 190, 0.5);
	opacity:0;
    height: 360px;
    position: absolute;
    width: calc(100% - 20px);
	margin:0 -20px;
    z-index: 2;
	color: #bec1c1;
	font-size: 80px;
	text-align: center;
	font-family: fontAwesome;
	padding: 0;
	transition: all 0.7s ease-in-out 0s;
	@media (max-width:767px) {
		display:none;
	}
}
.field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity:hover > .overlay {
    background-color: rgba(80, 130, 190, 0.5);
    z-index: 2;
	display:block;
	opacity:1;
	transition: all 0.7s ease-in-out 0s;
	@media (max-width:767px) {
		display:none;
	}
}
.node-themenuebersichtsseite .field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity > .overlay {
    height: 282px;
}
.node-themenuebersichtsseite .field.field-name-field-themenbild.field-type-paragraphs.field-label-hidden .entity {
	.field-name-field-verlinkung {
		@media (min-width:768px){
			margin-right:20px;
		}
	}
}
.field-name-galerie .field-name-field-bilder.field-type-paragraphs.field-label-hidden .entity {
	.field-name-field-kurze-bildbeschreibung {
		padding:10px 0;
		/*@media (min-width:768px){
			margin-right:20px;
		}*/
		border-bottom: 2px solid $colSecond;
		&:after {
			content: "";
			float: right;
			font-family: fontAwesome;
			font-size: 36px;
			/*margin-top: -10px;*/
		}
	}
}
@media (max-width:767px){
	.node-galerie .view-slideshow .col-lg-4 {
		margin: 0;
	}
}
.node-type-galerie .field-name-field-anrisstext {
	padding-top:15px;
}

.level-2 > a {
	@media(max-width:767px){
		border-bottom:none;
	}
	font-family:$fontGlobal;
}
.level-3 {
    margin-bottom: 10px;
}
.level-3 > a {
    color: #5082be;
    font-weight: 700;
}
#navbar_full_menu .menu.nav .menu.nav {
    font-weight: 300;
    line-height: normal;
}
#navbar_full_menu {
    color: #000;
    margin-top: -1px;
}
#bootstrap_large_menu.show_menu #navbar_full_menu {
    padding-bottom: 20px;
	padding-top: 15px;
}
.special_menu_akb_intern > span:before,
.special_menu_awb_intern > span:before,
.special_menu_vme_intern > span:before,
.special_menu_vdr_intern > span:before,
.special_menu_waz_intern > span:before,
.special_menu_wveb_intern > span:before {
    content: "\f023  ";
    font-family: fontAwesome;
}
#navbar-975 > span::before {
    content: "\f08b  ";
    font-family: fontAwesome;
}


.entity .overlay .marker {
    display: inline-block;
	padding-top: 70%;
    position: relative;
	opacity: 0;
	transition: all 0.3s ease-in-out 0s;
}
.entity:hover .overlay .marker {
    padding-top: 30%;
	opacity:1;
	transition: all 0.3s ease-in-out 0s;
}

ul, ol {
    padding-left: 0;
}

.col-md-8 {
	.field-name-field-text {
		p {
			margin: 0 0 25px;
		}
		ul{
			li {
				list-style:none;
				padding-left: 12px;
				&:before {
					content: "•";
					/*margin-right: 6px;*/
					margin-left: -12px;
					float:left;
				}
			}
		}
		ol{
			li {
				margin-left: 20px;
			}
		}
	}
}
.field-name-field-sidebar .field-name-field-video {
    margin-top: 2px;
}
.field-name-field-sidebar {
	clear:both;
	.field-name-field-video {
		margin-top: 2px;
		+ h3.field-label {
			display:none;
		}
	}
	a {
		color:$colFifth;
		&:hover {
			color:$colFourth;
		}
	}
	a.spamspan,
	.field-name-field-kontaktperson-email a,
	.file a {
		color:$colFont;
		&:hover {
			color:$colFourth;
		}
	}

	ul {
		padding:0;
		li {
			padding:0;
			margin:0 0 0 16px;
			list-style:none;
			&:before {
				content: "•";
				/*margin-right: 6px;*/
				float:left;
				margin-left:-14px;
			}
			a {
				/*padding: 0 0 0 16px;
				display: inline-block;*/

				font-family:$fontHeadlines;
				color:$colFont;
				font-weight:700;
				&:hover {
					color:$colFourth;
				}
				&:before {
					content: "";
					float: left;
					font-family: fontAwesome;
					font-size: 10px;
					margin: 2px 0 0 -16px;
				}
				&.ext:before {
					content: "";
				}
				.ext {
					display:none;
				}
			}
			&.linked{
				/*margin:0;*/
				&:before {
					content: none;
					margin-right: 0;
				}
			}
		}
	}
	.paragraphs-item-download {
		margin-bottom: 10px;
		.content {
			h3.field-label {
				display:none;
			}
			.field-name-field-anrisstext {
				font-family:$fontGlobal;
				font-weight:300;
			}
			@media (min-width:768px) {
				.field-name-field-foto {
					max-width:40%;
					float:left;
					margin-right: 10px;
					border: 1px solid #ddd;
				}
				.field-name-field-foto + .field-name-field-download {
					float: right;
					width:55%;
				}
				.field-name-field-ueberschrift{
					margin-left:45%;
				}
				.field-name-field-anrisstext {
					margin-left:45%;
					+ .field-name-field-download {
						float: right;
						margin-top:10px;
						/*margin-left:45%;*/
						width:55%;
					}
				}
			}
		}
	}
}
.file .file-icon {
    display: none;
}
.paragraphs-item-download {
	font-family: $fontHeadlines;
	margin-bottom: 2em;

	&:after{
		display: block;
		clear: both;
		content: "";
	}

	.field-name-field-ueberschrift{
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		color: $vdr-font-blue;
		margin: 20px 0;

		@include breakpoint($screen-md-min){
			font-size: 30px;
			line-height: 36px;
		}
	}
	.field-name-field-download {
		margin: 10px 0;
		.file {
			margin-top:17px;
			a{
				@include vdr-link-icon-responsive-hoverscale(32,50, '../img/Download.svg', 1.01);
				color: $vdr-font-blue;
				font-weight: 700;
				display: flex;
				align-items: center;

				@media screen and (max-width:($screen-md - 1)) {
					&::before {
						margin-top: 0;
					}
				}

			}
		}
	}

	&.view-mode-referenz{
		border-bottom: 1px solid $vdr-font-blue;
		padding-bottom: 29.5px;
		margin-top: 29.5px;
		.group-download{
			position: static;
			bottom: 29px;
			margin: 0;
			@include breakpoint($screen-sm){
				position: absolute;
				margin: 0 0 0 202px;
			}
		}
	}
}

.paragraphs-item-link-element{
	.field-name-field-verlinkung{
		margin: 10px 0;
	}
	a{
		@include vdr-link-icon-responsive-hoverscale(32,50, '../img/Link.svg', 1.01);
		color: $vdr-font-blue;
		font-weight: 700;
		display: flex;
		align-items: center;

		@media screen and (max-width: ($screen-md - 1)) {
			&::before {
				margin-top: 0;
			}
		}

	}
}

.file > a {
    display: inline-block;
}
.flright {
	float:right;
}
.field.field-name-share.field-type-ds.field-label-hidden {
  font-size: 12px;
	margin: 20px 0 40px 0;
	.socialBtn {
		text-align: center;
		li {
			display:inline;
			padding: 0 10px;
			a:hover {
				color:$colFifth;
			}
		}
	}
	.flright {
		padding-top:10px;
	}
}
@media (min-width:768px){
	.field-name-share {
		/*margin-top: -20px;*/
	}
}
/*node-type-standardseite*/
.node-type-standardseite, .node-type-themenuebersichtsseite {

	.paragraphs-items-field-element.paragraphs-items-field-element-zweispaltig {
		.entity.paragraphs-item-text {
			//remove if problems appear?
			margin-bottom: 0;
		}
	}
	&.page-climate .entity.paragraphs-item-text,
	&.page-landstrom .entity.paragraphs-item-text {
		.text-darkblue {
			background-color: #ACD5EA;
			padding-bottom: 10px;
		}
		.text-headerblue {
			text-align: center;
			background-color: #005fa1;
			h1, h2, p {
				margin: 0;
				text-align: center;
				color: white;

			}

			h1 {
				padding-left: 5px;
				padding-right: 5px;
				padding-bottom: 2rem;
			}

			p {
				padding-bottom: 3rem;
			}
		}

		img {

		}

		p, h2, h3 {
			text-align: left;
			font-weight: 600;
			padding: 0 50px;
			@media screen and (max-width: 1199px) {
				padding: 0 42px;
			}
			@media screen and (max-width: 992px) {
				padding: 0 31px;
			}
			@media screen and (max-width: 767px) {
				padding: 0 4.2vw;
			}
		}

		h2 {
			font-size: 24px;
			padding-top: 1rem;
		}
		h3 {
			padding-top: 2rem;;
		}

		p {
			padding-bottom: 2rem;
		}

		ul {
			font-weight: 600;
			padding: 0 75px;
			@media screen and (max-width: 1199px) {
				padding: 0 70px;
			}
			@media screen and (max-width: 992px) {
				padding: 0 60px;
			}
			@media screen and (max-width: 767px) {
				padding: 0 6vw;
			}

			li {
				margin: 1rem 0;
			}
		}
	}

	&.page-climate,
	&.page-landstrom {
		img {
			width: 100%;
		}
	}

	/*  .page-node-4064.node-type-standardseite .field-name-field-element .paragraphs-item-download */

	&.page-node-4046, &.page-node-4064, &.page-node-16856, &.page-node-16857, &.page-node-16860, &.page-node-16927, &.page-node-16928, &.page-node-16966, &.page-node-16964 {
		.field-name-field-element {
			display: inline-block;

			.paragraphs-item-download {
				margin: 0 6px 4em 6px;
			}
		}
	}


	.field.field-name-share.field-type-ds.field-label-hidden {
		.socialBtn {
			padding: 10px 0 0 0;
			border-top: none;
			margin:0;
		}
	}
	.anrisstext,
	.subheadline {
		font-weight: 400;
		font-family: $fontGlobal;
	}

	.paragraphs-items-field-element-zweispaltig.paragraphs-items-zweispaltig {
		.paragraphs-item-fullsize-bild.entity {
			margin-bottom: 0;

			img {
				width: 100%;
			}
		}
	}

	p, .region-content li.linked {
		a {
			font-weight: inherit;
			font-style: italic;
			color: $vdr-font-blue;
			text-decoration: underline;

			&:hover {
				color: $colFourth;
				transition: all 0.5s ease 0s;
			}
		}
	}

}
.control-label {
    font-family: "Bitter",sans-serif;
    font-size: 18px;
    margin: 0;
}
.radio, .checkbox {
    /*min-height: 16px;*/
    padding-left: 0px;
	display:inline-block;
	margin: 0 20px 0 0;
}
.radio + .radio, .checkbox + .checkbox {
    margin-top: 0;
}
input.form-radio {margin-top:6px;}
#edit-submit, #edit-cancel {
	font-family: $fontHeadlines;
	font-weight:700;
	background-color: $vdr-font-blue;
	border-color: $vdr-font-blue;
	font-size: $sizeStandard;
    border-radius: 0;
    border-width: 0;
    color: $colBG;
    line-height: 26px;
    margin: 0;
    padding: 3px 10px !important;
    white-space: nowrap;
	transition:none;
	display: inline-block;
	vertical-align: middle;
	&:hover {
		background-color: $vdr-cyan;
		border-color: $vdr-cyan;
		transition:none;
	}
}

/*input.form-radio:not(:checked) + label, .radio label {
    background: transparent url("../img/radiobuttonunchecked.png") no-repeat scroll left bottom;
}
input.form-radio:hover:not(:checked) + label, #widgetu13301.focus-st #u13304 input:not(:checked) + label {
    background: transparent url("../img/radiobutton.png") no-repeat scroll left center;
    height: 16px;
    min-height: 0;
    width: 16px;
}
input.form-radio:checked:hover + label, input.form-radio:checked {
    background: transparent url("../img/radiobuttonchecked.png") no-repeat scroll left top;
    height: 16px;
    min-height: 0;
    width: 16px;
}
input[type="checkbox"] + label, input[type="radio"] + label {
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 100%;
    width: 100%;
}
input.form-radio {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}


.radio label.control-label {
    background-image: url("../img/radiobutton.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    display: block;
    float: left;
    height: 16px;
    margin: 0 10px 0 0;
    width: 23px;
    cursor: pointer;
}

.radio label.control-label {
    margin-right: 15px;
    width: auto;
}
.radio input:checked + label.control-label {
    background-position: left top;
}
input[type="radio"] {
	position:absolute;
	clip:rect(1px 1px 1px 1px);
}*/

.node-veranstaltung,
.node-galerie,
.node-listenmodul,
.node-beitrag,
.node-download,
.node-rundschreiben {
	.field.field-name-field-anrisstext {
		/*font-family: $fontHeadlines;
		font-weight:700;
		> p {
			padding-top:15px;
		}*/
	}
	.paragraphs-item-bild {
		.field.field-name-field-anrisstext {
			&:after {
				border-bottom: none;
				content: " ";
				display: block;
				height: 2px;
				padding: 0 0 17px;
				width: 100%;
			}
		}
	}
	.paragraphs-item-download{
		.content{
			.field.field-name-field-anrisstext{
				font-weight:300;
				font-family:$fontGlobal;
			}
		}
	}
	.paragraphs-item-video {
		padding: 20px 0 0;
		h3.field-label {display:none;}
		.field-type-image {
			@media (min-width:768px){
				float:left;
				margin-right:20px;
			}
			margin-bottom:20px;
			@media (max-width:767px){
				img {
					width:100%;
				}
			}
		}
		.field.field-name-field-anrisstext {
			font-family: $fontGlobal;
			font-weight: 300;
			@media (min-width:768px){
				margin-left:350px;
				min-height: 204px;
			}
			&:after {
				/*margin-bottom:20px;*/
				border:none;
			}
			p{
				padding:0;
			}
		}
		&:after {
			border-bottom: 2px solid $colSecond;
			clear: both;
			content: " ";
			display: block;
			/*padding: 0 0 20px;*/
		}
	}
	.field-name-field-sidebar {
		.paragraphs-item-video {
			.field.field-name-field-anrisstext {
				min-height: 20px;
				@media (min-width:992px){
					margin-left:0;
				}
			}
			@media (min-width:992px){
				.field-type-image {
					float:none !important;
					margin-right:0 !important;
				}
			}
		}
	}
	/*&.node-teaser:before {
		content: "";
		float: right;
		font-family: fontAwesome;
		font-size: 36px;
		margin-top: -20px;
		position: absolute;
		right: 0;
		top: 50%;
	}
	&.node-teaser .field-name-title {
		margin-left:180px;
	}*/
	&.node-teaser .field-name-field-anrisstext{
		font-family: source sans pro;
		font-weight: 300;
		@media (min-width:768px){
			margin-left: 200px;
		}
		/*padding: 0 40px 30px !important;*/
		&:after {
			border-bottom: none;
			content: " ";
			display: block;
			height: 2px;
			/*padding: 0 0 17px;*/

			padding:0;
			width: 100%;
			clear:both;
		}
	}
	&.node-teaser .col-md-12  {
		/*border-bottom: 2px solid $colSecond;*/
	}
	.dateLocation,
	.field-name-field-datum{
		margin-top: 15px;
		padding-bottom:0 !important;
		font-size: 15px;
		line-height: 23px;
		display:inline-block;
		font-weight:300;
		@include breakpoint($screen-md-min){
			margin-top: 0;
			font-size: 18px;
		}
		.date-display-single {
			font-weight: 400;

		}

		.date-display-single + .field-name-field-titel-ort/*,
		+ .field-name-field-ort*/ {
			margin-left: -5px;
			&:before {
				content: "| ";
			}
		}
	}

	.dateLocation{
		.date-display-single + .field-name-field-titel-ort/*,
		+ .field-name-field-ort*/ {
			margin-left: 5px;
			&:before {
				content: "| ";
				display: inline;
			}
		}
	}


	.field-name-field-titel-ort, .field-name-field-ort	{
		display: inline-block;
		margin: 0;
		font-weight: 300;
		&:before{
			content: "|";
		}
	}
	.dateLocation{
		> .date-display-single .date-display-range {
			display: inline-block;
		}
		> .date-display-range {
			display: inline-block;
			padding: 0 10px;
			background-color: $colSecond;
			font-family: $fontHeadlines;
			font-weight: 700;
		}
		.field-name-field-titel-ort {
			margin-left: -5px;
			&:before{
				content: "| ";
			}
		}
	}
	.field-name-field-foto + .field-name-field-datum{
		padding-bottom:0 !important;
		font-size:18px;
		.date-display-single {
			background-color:transparent;
			font-family:$fontHeadlines;
			font-weight:700;
			padding: 0;
			height:24px;
			display:inline-block;

		}
	}
	.field-name-field-uhrzeit {
		float: left;
		font-family: $fontHeadlines;
		font-weight:700;
		width: 110px;
		+ div {
			margin-left: 110px;
		}
	}
	.field-name-field-element {
		/*h2 {
			margin-bottom: -5px;
		}*/
		h4 {
			/*margin-bottom: -5px;*/
			padding-bottom: 3px;
		}
	}
	.col-md-8 div:nth-last-child(2) .paragraphs-item-video::after {
		border-bottom: medium none !important;
	}
}
.node-beitrag .paragraphs-item-download .field-name-field-foto + .field-name-field-ueberschrift + .field.field-name-field-anrisstext::after {
    border-bottom: none;
}
.node-download,
.node-veranstaltung {
	.field-name-field-anrisstext {
		padding: 17px 20px 0px !important;
	}
	&.view-mode-full .field-name-field-anrisstext + .paragraphs-items {
		padding-top: 20px !important;
	}
	&.node-teaser {
		.field.field-name-field-anrisstext {
			padding: 0px !important;
		}
	}
}

.field-name-galerie-referenz > h2.block-title {
	padding-top:0;
}

.view-downloads,
.view-beitragsmodul {
	.node-veranstaltung.node-teaser:before,
	.node-galerie.node-teaser:before,
	.node-listenmodul.node-teaser:before {
		content: none;
	}
}

.node-galerie.node-teaser.view-mode-teaser {
	cursor:pointer;
	transition: color 0s ease 0s, opacity 0.5s ease 0s;
	/*border-bottom: 2px solid $colSecond;
	margin-bottom:23px;*/
	&:hover {
		color: $colFourth;
		transition: color 0.5s ease 0s;
		img {
			opacity:0.5;
			transition: color 0s ease 0s, opacity 0.5s ease 0s;
		}
	}
	.field-name-post-date {
		font-family: $fontHeadlines;
		float:left;
		&:after {
			content: " | ";
			margin-right:5px;
		}
	}
	.paragraphs-items-field-bilder {
		/*background-color: #5082be;*/
		width:160px;
		/*height:160px;*/
		@media (min-width:768px){
			float:left;
		}
		margin-right:20px;
		margin-bottom: 15px;
		position:relative;
		/*.field-name-field-bilder {
			bottom: 0;
			position: absolute;
			vertical-align: bottom;
		}*/
	}
}

.node-listenmodul .field-name-field-element {
	border-bottom: 2px solid $colSecond;
	margin-bottom:23px;
	.field-name-field-foto {
		width:160px;
		/*height:160px;*/

		margin-right:20px;
		margin-bottom: 15px;
		position:relative;
		@media (min-width:768px){
			float:left;
			+ .field-name-field-text {
				margin-left: 180px;
				+ .field-name-field-verlinkung {
					margin-bottom: 20px;
					margin-left: 180px;
				}
			}
		}
	}

	.field-name-field-verlinkung {
		margin-bottom: 20px;

	}
	&:last-child {
		border-bottom: none;
	}
	&:first-child {
		padding-top: 20px;
	}
}
@media (min-width:768px){
	.node-type-listenmodul {
		.node-listenmodul {
			.entity {
				.field-name-field-foto + strong + div{
					margin-left: 180px;
				}
				.field-name-field-download{
					margin-left: 180px;
					margin-bottom:10px;
				}
			}
		}
	}
}

.node-beitrag,
.node-standardseite,
.node-veranstaltung {
	.paragraphs-item-text-bild-element{

		>* {


				margin: 2rem auto;

		}
	}
	.paragraphs-item-text-zweispaltig {

		margin: 10px 0;
		>[class^="group"] {
			padding: 0 20px;
		}
	}
	.paragraphs-items-field-element{
		.paragraphs-item-bild{
			.field-name-field-foto{
				margin-bottom: 15px;
			}
			.field-name-field-anrisstext{
				font-style: italic;
				color: $vdr-font-blue;
				font-weight: 600;
			}
		}
	}

	.paragraphs-item-download {
		.field-name-field-foto {
			float: none;
			margin-right: 0;
			padding-top: 0 !important;
			@include breakpoint($screen-sm){
				float: left;
				margin-right: 20px;
			}
			+ .field-name-field-anrisstext,
			+ .field-name-field-verlinkung + .field-name-field-anrisstext{
				font-family: $fontGlobal !important;
				font-weight: inherit !important;
				font-size:inherit !important;
				text-align: left;
				margin-top: 0;
				line-height: inherit;
			}
		}
	}
	.col-md-8 .paragraphs-item-download {
		clear: both;
	}
	.field-name-field-sidebar {
		.field-name-field-foto {
			+ .field-name-field-anrisstext,
			+ .field-name-field-verlinkung + .field-name-field-anrisstext{
				font-size:inherit !important;
				text-align: left;
			}
		}
	}
}


.node-listenmodul .paragraphs-items-field-element *:nth-last-child(+2) {
	border-bottom: none;
}

.view-listen .view-content .views-row-last .node-galerie.node-teaser.view-mode-teaser {
    border-bottom: none;
}

.col-md-8 .paragraphs-item-text .field-name-field-text,
.col-md-8 .paragraphs-item-listen-element .field-name-field-text {
	h3:first-child {
		padding-top: 0;
	}
}
.col-md-8 .field-name-title + .paragraphs-items .paragraphs-item-text .field-name-field-text {
	h3:first-child {
		padding-top: 15px;
	}
}
.paragraphs-item-bild .content .field-name-field-verlinkung {
    font-size: 15px;
    margin-top: 9px;
    text-align: right;
	display:none;
}
.paragraphs-items-field-sidebar {
	.entity.entity-paragraphs-item.paragraphs-item-bild {
		margin-bottom: 20px;
	}
}

.not-logged-in .menu span.icon-lock {
	display: none;
}
.nav > li > a.icon-sign-out {
	padding: 0 10px 0 0;
	@media (max-width:767px){
		padding: 0 10px 0 20px;
	}
	&:before {
		content: "";
		font-family: fontAwesome;
	}
}

.carousel-control {
	text-shadow:none;
	opacity: 1;
	.icon-prev:before {
		content: "";
		font-family: fontAwesome;
		color: $colSecond;
		font-size:55px;
	}
	.icon-next:before {
		content: "";
		font-family: fontAwesome;
		color: $colSecond;
		font-size:55px;
	}
	&:hover {
		span:before {
			color:#fff;
		}
	}
	&.right {
		background-image: none;
	}
	&.left {
		background-image: none;
	}
}
.field-name-field-kurze-bildbeschreibung {
    font-size: 15px;
    font-weight: 400;
	margin-top: 5px;
}
.carousel-inner .field-name-field-kurze-bildbeschreibung {
    min-height:80px;
}
.carousel-indicators li {
    border-radius: 0;
}

.view-listen,
.view-beitragsmodul,
.view-downloads {
	> .text-center {
		height: 0;
	}
}
.pagination {
    margin: 0;
		z-index: 1;
    @media (min-width:768px){
			position: relative;
			top: -15px;
			height:0;
		}
	>li{
		&:after{
			color: $vdr-font-blue;
			content: "|";
			float: left;
			font-size: 18px;
			font-weight:400;
		}
		> a, > span{
			background-color: transparent;
			border: none;
			color: $vdr-font-blue;
			float: left;
			line-height: 1.42857;
			padding: 0px 6px;
			position: relative;
			text-decoration: none;
			font-size: 18px;
			font-weight: 600;
			&:hover, &:focus{
				background-color: transparent;
				border: none;
				color: $vdr-font-hover;
				z-index: 3;
			}
		}
		&.active{
			a, span{
				background-color: transparent;
				border: none;
				color: $vdr-font-blue;
				cursor: default;
				z-index: 2;
				&:hover, &:focus{
					background-color: transparent;
					border: none;
					color: $vdr-font-blue;
					cursor: default;
					z-index: 2;
				}
			}
		}
	}
	> .disabled > span{
		background-color: transparent;
		color: $vdr-font-blue;
		&:hover, &:focus{
			background-color: transparent;
		}
	}

}
.field-name-galerie .pagination {
    top: 133px;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {

}

.pagination li:after {

}
.pagination li.next:after,
.pagination li.pager-last:after,
.pagination li.pager-first:after,
.pagination li.prev:after {
    content: none;
}


@media (max-width:767px){
	#page-header + .row {
		margin-bottom: 150px;
		margin-left:-10px;
		margin-right:-10px;
	}

	#loginButton {
		padding: 10px 0;
		position: absolute;
		width:100%;
		top: -140px;
		font-size: 26px;
	}
	.pre_footer.container {
		margin: 0 -40px;
	}
	#block-user-login {
		h2.block-title,
		form div:first-child {
			display:none;
		}
		#user-login-form {
			padding: 20px;
			margin-bottom: 40px;
			li {
				list-style:none;
				a {
					font-family: $fontHeadlines;
					&:before{
						content: "";
						font-family: fontAwesome;
						margin-right: 5px;
					}
					&:hover {
						color:$colBG;
					}
				}
			}
			#edit-actions {
				margin: 0 auto;
				text-align: center;
				#edit-submit {
					width: 60%;
					background-color: transparent;
					border-color: $colFont;
					border-width: 3px 0 0 0;
					color: $colFont;
					transition: none ;
					&:hover {
						background-color: transparent;
						border-color: $colBG;
						color: $colBG;
						transition: none;
					}
				}
			}
		}
	}
}


#gremienStruktur {
	text-align:center;
	a {
		/*display:block;*/
	}
	> div {
		padding:20px;
		box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.17);
		margin: 10px;
	}
	h3 {
		margin: 0;
		padding: 0;
	}
	.president {
		background-color: #ffe163;
	}
	.vice {
		background-color: #ffe687;
	}
	.cols2 {
		padding: 0 !important;
		box-shadow: none;
		&:after {
			clear: both;
			content: " ";
			display: block;
		}
		.left {
			margin-right:2%;
		}
		.left, .right {
			background-color: #ffeca0;
			box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.17);
			float: left;
			padding: 20px;
			width: 49%;
		}
	}
	.geschfueh {
		background-color: #fff1b8;
		h4 {
			border: none !important;
		}
	}
	.abteilung {
		background-color: #fff5d0;
	}
	.ausschuesse {
		background-color: #fff9e0;
	}
	.mitglieder {
		background-color: #fffbea;
	}

	@media (max-width:767px){
		.vice, .hgf, .praesidium, .gf {
			clear: both;
			height: auto;
			width: 100% !important;
		}
		.vice, .praesidium {
			margin-bottom:10px;
		}
	}
	@media (min-width:768px){
		.vice, .hgf, .praesidium, .gf {
			height: 210px;
		}
	}
	@media (min-width:992px){
		.vice, .hgf, .praesidium, .gf {
			height: 170px;
		}
	}

}

.domain-vme-web {
	#gremienStruktur .cols2.vorstandVME .left {
		background-color: #a9d0e0;
		box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.17);
		@media (min-width:768px){
			float: left;
			width: 49%;
		}
		padding: 20px;
	}
	#gremienStruktur .cols2.vorstandVME .left {
		background-color: rgba(0, 0, 0, 0);
		box-shadow: none;
		@media (min-width:768px){
			margin: 0 2% 0 0;
		}
		padding: 0;
	}
	#gremienStruktur .cols2.vorstandVME .left > div {
		background-color: #a9d0e0;
		box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.17);
		padding: 10px;
		width: 100%;
		&:first-child {
			margin: 0 0 10px;
		}
	}
	#gremienStruktur .vorstand,
	#gremienStruktur .gf,
	#gremienStruktur .abteilung {
		background-color: #a9d0e0;
	}
	#gremienStruktur .vorsitzMitgliederrat,
	#gremienStruktur .Mitgliederrat,
	#gremienStruktur .ausschuesse,
	#gremienStruktur .mitgliederversammlung{
		background-color:#ededed;
	}
	@media (max-width:767px){
		#gremienStruktur > div {
			margin: 10px 0;
		}
		#gremienStruktur .cols2 .left,
		#gremienStruktur .cols2 .right {
			clear: both;
			height: auto;
			width: 100% !important;
		}
		#gremienStruktur > div > div {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (min-width:768px){
		#gremienStruktur .vorstand	{
			height: 260px;
		}
		#gremienStruktur .Mitgliederrat,
		#gremienStruktur .vorsitzMitgliederrat	{
			height: 320px;
		}
		#gremienStruktur .stvVorstandsvorsitz {
			height: 180px;
		}
	}
	@media (min-width:992px){
		#gremienStruktur .vorstand	{
			height: 190px;
		}
		#gremienStruktur .vorsitzMitgliederrat,
		#gremienStruktur .Mitgliederrat	{
			height: 220px;
		}
		#gremienStruktur .stvVorstandsvorsitz {
			height: 110px;
		}
	}
	@media (min-width:1200px){
		#gremienStruktur .vorstand,
		#gremienStruktur .vorsitzMitgliederrat,
		#gremienStruktur .Mitgliederrat	{
			height: 190px;
		}
		#gremienStruktur .stvVorstandsvorsitz {
			height: 110px;
		}
	}
}

@media (max-width:767px){
	#mm-0 #page-main-container {
		#page-header {
			margin-top: 50px;
			margin-left: -20px;
			margin-right: -20px;
			/*height:400px;*/

		}
		> .row .col-sm-12 {
			overflow:hidden;
			/*.region-content #block-system-main {
				.node-themenuebersichtsseite .row .col-md-12 {
					.field-name-title {
						display:none;
						+ div {display:none;}
					}
					.paragraphs-items {display:none;}
					.field-name-share {display:none;}
				}
			}
			.breadcrumb {display:none;}*/
		}
	}
	.front #page-header + .row{
		clear:both;
	}
}
/*@media (max-width:480px){
	#mm-0 #page-main-container {
		#page-header {
			margin-top: 50px;
			margin-left: -20px;
			margin-right: -20px;
			height:200px;
		}
	}
}*/
@media (max-width:767px){
	.logged-in {
		#mm-0 #page-main-container {
			#page-header {
				margin-top: 72px;
			}
		}
		#navbar {
			top:40px;
		}
	}
}
@media (min-width:606px) and (max-width:767px){
	.logged-in {
		#mm-0 #page-main-container {
			#page-header {
				margin-top: 52px;
			}
		}
		#navbar {
			top:20px;
		}
	}
}
@media (max-width:359px){
	.logged-in {
		#mm-0 #page-main-container {
			#page-header {
				margin-top: 89px;
			}
		}
		#navbar {
			top:57px;
		}
	}
}

.pre_footer.container {
    margin:0;
	a {
		display:block;
	}
}

.navbar-toggle{
	@include breakpoint(max-width $screen-sm-max){
		display: block;
	}
}

.navbar-collapse.collapse{
	@include breakpoint(max-width $screen-sm-max){
		display: none !important;
	}
}

.navbar-right{
	@include breakpoint(max-width $screen-sm-max){
		float: none !important;
	}
}
@media (min-width: 769px) {
	.navbar-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none;
	}
	.navbar-collapse.in {
		overflow-y: visible;
	}
	.navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
		padding-left: 0;
		padding-right: 0;
	}
}

.btnArchiv > a {
    background-color: $colSecond;
    color: $colFont;
    display: block;
    font-family: $fontHeadlines;
    font-size: 20px;
    margin: -85px -20px 20px;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
}
.btnArchiv > a:hover {
    background-color: $colFifth;
    color: $colFont;
}

.page-printmail #page-main-container > .row {
	margin: 0;
}
.page-printmail #page-main-container section {
    background-color: $colBG;
}
#print-mail-form {
	padding-bottom: 20px;
	label.printmail-label {
		width: 185px;
	}
	label {
		font-family: $fontHeadlines;
		font-size: 18px;
		margin: 0;
	}
}

.node-download > .row div:first-child > h3 {
    background-color: $colBG;
    margin: 0;
    padding: 10px 20px 0;
}

body.no_scroll_effects .mm-page{
	background-color: rgba(255,255,255,1) !important;
}

/* Ausblenden des Enddatums sowie Uhrzeit bei Inhaltstyp Beitrag, Downloads, Rundschreiben */
#beitrag-node-form,
#download-node-form,
#rundschreiben-node-form {
	.form-item-field-datum-und-0-show-todate,
	.form-item-field-datum-und-0-value-hour,
	.form-item-field-datum-und-0-value-minute  {
	display:none !important;
	}
}
.file-size {
	display:none;
}

.ui-widget {
	font-size:.8em;
}
.ui-datepicker-header {
	a {
		transition: none !important;
		&:hover {
			transition: none !important;
			top:2px;
			&.ui-datepicker-prev-hover{
				left:2px;
			}
			&.ui-datepicker-next-hover{
				right:2px;
			}
		}
	}
}
.field-name-title {
	+ .paragraphs-items {
		.col-md-12 .field-name-field-text p:first-child {
			margin-top: 15px;
		}
		.entity-paragraphs-item .content .field-name-field-foto:first-child {
			padding-top: 10px;
		}
		.entity-paragraphs-item .content .node-kontaktperson .field-name-field-foto {
			padding-top: 0px;
		}
	}
}
.node-listenmodul {
	.field-name-title {
		+ .paragraphs-items {
			.col-md-12 .field-name-field-text p:first-child {
				margin-top: 0px;
			}
		}
	}
}

#edit-field-schlagwort-akb-tid-selective,
#edit-field-schlagwort-awb-tid-selective,
#edit-field-schlagwort-vme-tid-selective,
#edit-field-schlagwort-vdr-tid-selective,
#edit-field-schlagwort-waz-tid-selective,
#edit-field-schlagwort-wveb-tid-selective {
    width: 200px;
}


.ui-datepicker-no-highlight .ui-state-active {
	background: #e6e6e6 none repeat scroll 0 0;
    border: 1px solid #d3d3d3;
    color: #555;
    font-weight: normal;
}
.ui-datepicker-no-highlight .ui-state-hover {
	background: #dadada none repeat scroll 0 0;
    border: 1px solid #999;
    color: #212121;
    font-weight: normal;
}

.page-search {
	.page-header{
		border-bottom: 0;
	}
	.region-content > .block-system > .container{
		padding: 0;
		width: 100%;
	}

	#page-header + .row  > section.col-sm-12 {
		.page-header,
		.tabs--primary.nav.nav-tabs,
		.region-content	{
			background-color: $colBG;
			padding-left:20px;
			padding-right:20px;
		}

		.region-content #block-system-main > .container {
			margin:0;
			padding:0;
			width:100%;
			/*.input-group .form-control {
				width: auto;
			}
			.input-group-btn {
				float: left;
			}*/
		}
		.tabs--primary.nav.nav-tabs	{
			padding-top:20px;
			border-bottom:1px solid $colBorder2;
			border-left: 20px solid $colBG;
			border-right: 20px solid $colBG;
		}
		.tabs--primary.nav.nav-tabs:before {
			background-color: $colBG;
			content: " ";
			display: inline-block;
			float: left;
			height: 70px;
			margin-left: -40px;
			margin-top: -20px;
			position: relative;
			width: 20px;
		}
		h1.page-header {
			margin: 0;
			padding: 15px 20px 2px 0;
			border-left: 20px solid $colBG;
			border-right: 20px solid $colBG;
			&:before {
				background-color: #fff;
				content: " ";
				display: inline-block;
				float: left;
				height: 75px;
				margin-left: -20px;
				margin-top: -16px;
				position: relative;
				width: 20px;
			}
			&:after {
				background-color: #fff;
				content: " ";
				display: inline-block;
				float: right;
				height: 75px;
				margin-right: -40px;
				margin-top: -16px;
				position: relative;
				width: 20px;
			}
		}
	}
	ol.search-results.node-results {
		padding-left: 20px;
	}
	.pagination {
		margin: 40px 0;
		position: static;
	}
	.alert-danger {
		border: 20px solid $colBG;
		margin:0;
	}
	input[type="text"] {
		background-color: $colInputTxt;
		border: 1px solid $colInputTxt;
		box-shadow: none;
		color: #000;
		font-size: 20px;
		padding: 6px;
		z-index: 0;
	}
}
.field-name-field-pressedisclaimer {
	font-size:15px;
	font-weight:400;
	margin-bottom: 15px;
}
span.ext {
    display:none;
}
hr {
    border-color: $vdr-font-blue;
	border-width: 2px 0 0;
}
dl.pressRelease {

	dt {
		clear: left;
		float: left;
		width: 75%;
		font-weight:normal;
	}
	dd{
		float:right;
	}
}

.paragraphs-items-field-sidebar {
	.field-name-field-ueberschrift h2 {
		margin-bottom: -10px;
		padding-top: 0;
	}
	.field-type-paragraphs {
		margin-bottom:30px;
		&:first-child {
			padding-top:20px;
		}
		.entity-paragraphs-item .content:after {
			clear: both;
			content: " ";
			display: block;
		}
	}
	> div:last-child {
		margin-top:-30px;
	}
}

.page-node-308.domain-wveb-web .node-standardseite > .row > .col-md-4,
.page-node-1603.domain-wveb-web .node-standardseite > .row > .col-md-4,
.page-node-183.domain-vdr-web .node-standardseite > .row > .col-md-4,
.page-node-497.domain-vdr-web .node-standardseite > .row > .col-md-4,
.page-node-1818.domain-vme-web .node-standardseite > .row > .col-md-4,
.page-node-1641.domain-vme-web .node-standardseite > .row > .col-md-4,
.page-node-1862.domain-akb-web .node-standardseite > .row > .col-md-4,
.page-node-2046.domain-akb-web .node-standardseite > .row > .col-md-4,
.page-node-1856.domain-awb-web .node-standardseite > .row > .col-md-4,
.page-node-2053.domain-awb-web .node-standardseite > .row > .col-md-4 {
    margin-top: 85px;
	.paragraphs-items-field-sidebar {
		.field-type-paragraphs {
			margin-bottom:20px;
			&:first-child {
				padding-top:0px;
			}
		}
	}
}

@media (min-width:768px){
	.view-id-listen.view-display-id-block_4 {
		padding-bottom:20px;
		&:after {
			clear: both;
			content: " ";
			display: block;
		}
		.view-content {
			.views-row {
				border: none;
				float: left;
				font-family: $fontHeadlines;
				margin: 0;
				padding-bottom: 0;
				padding-right: 0;
				width: 49%;
				min-height: 100px !important;
				clear: none !important;
				z-index: 1;
				&.views-row-even {
					float: right;
				}
				&:before {
					content: none;
				}
				.field-content a {
					min-height: 10px !important;
					margin-left: 95px;
					display:block;
				}
				.field-name-field-foto {
					height: 75px;
					width: 75px;
					margin-left: -95px;
					float:left;
				}
			}
		}
	}
}

#views-bootstrap-carousel-1 > .carousel-indicators {
	display:none;
}
.view-filters {
    margin-bottom: 50px;
}


/* responsive source set header */

#block-coma-beitrag-header {
	height: 173px;

	@include breakpoint($screen-sm-min){
		height: 405px;
	}
	@include breakpoint($screen-md-min){
		height: 405px;
		margin-top: 65px;
	}

	& > .field {
		height: 100%;
		overflow: hidden;
		position: relative;

		/* default and fallback */
		img.img-responsive {
			min-height: 100%;
			min-width: 100%;
			max-width: none !important;
			position: absolute;
			top: 50%;
			left: 50%;
			-ms-transform: translate(-50%,-50%);
			-webkit-transform: translate(-50%,-50%);
    		transform: translate(-50%,-50%);


			@include breakpoint(max-width 320px){
				width: auto !important;
				height: 100% !important;
			}
		}

		/* with picture source set */
		picture > img.img-responsive {
			@include breakpoint(max-width 319px){
				width: 100% !important;
				height: auto !important;
			}
			@include breakpoint(max-width 100px){
				width: auto !important;
				height: 100% !important;
			}
		}
	}
}
body.front{
	#block-coma-beitrag-header{

	}
}
.file-video iframe {
	max-width:100%;
	max-height:100%;
}
#search_form .search-form .input-group .form-control.form-text,
.mm-search input {
    font-size: 18px;
}

.domain-wazb-web {
	.paragraphs-item-kontaktformular h2 {
		display: none;
	}
	.paragraphs-item-kontaktformular .content {
		padding-top: 15px;
	}
	#kontakt-wazb-entityform-edit-form {
		margin-bottom:20px;
	}
}

.node-type-veranstaltung {
	.field-name-share {
		margin-top: 0px;
	}
}

.domain-wveb-web.page-node-1704 {
	.node-standardseite {
		.col-md-8 {
			> .field-name-title {
				padding-bottom: 15px !important;
			}
			.paragraphs-items .entity .view-listen {
				.item-list {
					border-bottom: 2px solid $colSecond;
					margin-top: 10px;
					padding-bottom: 10px;
					&:last-child {
						border-bottom:none;
					}
					li.views-row {
						border-bottom: none;
						list-style: none;
						padding: 0;
						margin: 10px 0;
					}
				}
			}
		}
	}
}
.node-wissenschaftler .col-md-8 > .field-name-title:first-child {
    padding-bottom: 15px !important;
}

iframe.meuhr {
	height:1600px;
	@media (min-width:768px){
		height:850px;
	}
	/*@media (min-width:992px){
		height:850px;
	}*/
}
a.btn-primary,
a.btn-primary:hover {
	color:$colBG;
}

.domain-vdr-web .vme-presse-mehr {
	display:none;
}

.domain-vme-web .vdr-presse-mehr {
	display:none;
}


.page-node-1704.domain-wveb-web,
.page-node-1703.domain-wveb-web {
	.view-listen .views-row:before {
		content: none;
	}
	.item-list li{
		a{
			display:block;
			&:after {
				content: "";
				float: right;
				font-family: fontAwesome;
				font-size: 36px;
				margin-top: -14px;
				position: absolute;
				right: 0;
				top: 50%;
				color:$colFont;
			}
		}
		&:hover a{
			display:block;
			&:after {
				content: "";
				float: right;
				font-family: fontAwesome;
				color:$colFourth;
			}
		}
	}
	.views-row .views-field-title{
		a{
			display:block;
			&:after {
				content: "";
				float: right;
				font-family: fontAwesome;
				font-size: 36px;
				margin-right: -20px;
				position: relative;
				color:$colFont;
			}
		}
		&:hover a{
			display:block;
			&:after {
				content: "";
				float: right;
				font-family: fontAwesome;
				color:$colFourth;
			}
		}
	}
	.field-name-field-institut{
		color:$colFont;
	}
}

.page-node-1640 {
	.view-downloads .views-row::before,
	.view-beitragsmodul .views-row::before,
	.view-listen .views-row::before {
		content: none;
		float: right;
		font-family: fontAwesome;
		font-size: 36px;
		margin-top: -20px;
		position: absolute;
		right: 0;
		top: 50%;
	}
	.views-field-title {
		a:after {
			content: "";
			float: right;
			font-family: fontawesome;
			font-size: 36px;
			position: absolute;
			right: 0;
			top: 35%;
			color:$colFont;
		}
		&:hover a:after {
			color:$colFourth;
		}
	}
}

.page-user,
.page-user-edit {
	#page-main-container {
		#page-header + .row > section {
			h1.page-header,
			.region-content{

			}
		}
	}
	.group-user-nutzerdaten,
	#edit-actions{
		background-color: $colBG;
		padding: 15px;
	}
}

/* EDIT 2017-01-11: webform elements */
.webform-component-time .form-control, .webform-component-date .form-control {
	display: inline !important;
	width:auto !important;
}
.webform-component-fieldset .panel-title {
	font-size:$sizeStandard;
	font-weight:bold;
}
.webform-component .control-label {
	font-family:$fontGlobal;
}
.webform-component .description {
	font-size:$sizeSubmenu;
}
.webform-component .webform-grid {
	font-size:14px;
}

/* EDIT 2017-02-28: jquery countdown timer */
.countdownHolder {
	width:auto !important;
}

/* EDIT 2017-06-15: webform elements */

.webform-component-checkboxes .radio, .webform-component-checkboxes .checkbox, .webform-component-radios .checkbox, .webform-component-radios .radio {
	display:block;
}
.form-item.webform-component {
	margin-bottom:20px;
}

.form-group:last-child{
	margin-bottom: 20px;
}

/* EDIT 2017-07-04: comments */

.view .views-field-views-conditional {
	padding-bottom:15px;
	display:block;
	/*float:right;*/
	text-align:right;
	+ .views-field-comment-count{
		display: inline-block;
		float: left;
		margin-top: -1.95em;
	}
}


.page-comment #block-system-main {
	background: #fff none repeat scroll 0 0;
	padding:20px;
}

/* EDIT 2018-01-26: new pt type button for sidebar */

.field-name-field-sidebar .paragraphs-item-button .field-name-field-text {
	background: $colSecond;
	padding:10px;
	text-align:center;
}

.paragraphs-item-infobox{
	margin-top: 10px;
	&.view-mode-full{
		background-color: $colBG;
		padding: 25px;
		.field-name-field-ueberschrift{
			font-size: 20px;
			line-height: 24px;
			font-family: $fontHeadlines;
			color: $vdr-font-blue;
			margin-bottom: 20px;
			@include breakpoint($screen-md-min){
				font-size: 28px;
				line-height: 33px;
			}
		}
	}
}

.field-name-field-text{
	ul{
		list-style-position: outside;
		padding-left: 15px;
		@include breakpoint($screen-md-min){
			padding-left: 20px;
		}
	}
}

.view-vdr-mitglied:not(.view-display-id-attachment_1) {
	.attachment-before {
		@media all and (min-width: $screen-sm){
			width: 570px;
			text-align: center;
			margin: 0 auto;
		}
		.view-vdr-mitglieder{
			a{
				&:hover{
					text-decoration: underline;
					text-decoration-color: $colMinor;
				}
			}
		}
	}
	@media all and (min-width: $screen-sm){
		.view-filters {
			button {
				margin-top: 2.35em;
			}
		}
	}


	>.view-content {
		margin: 1.25rem 0;
		.views-row {
			padding:10px 0;

			&:not(.views-row-last){
				border-bottom: 1px solid $colMinor;
			}
		}
	}
}

.node-mitglied {

	margin-bottom: 10rem;

	.field{

		margin: 1.25rem 0;

		&.field-name-field-strasse{
			margin-bottom: 0;
		}

		&.field-name-field-plz,
		&.field-name-field-ort {
			margin-top: 0;
			display: inline-block;
		}

		&.field-name-field-verlinkung{
			margin-top: 1.25rem;
		}

		&.field-name-field-google-map {
			margin: 0;
		}
	}

	h3 {
		margin-bottom: 0;
	}
}


.view-vdr-mitglied .btn-info.form-submit {
		background-color: #005fa0 !important;
		border-color: #269abc;
}

#loginvdr.vdr-login-modal {
	@media all and (min-width: $screen-md-min){
		.modal-dialog {
			width: 515px;
		}
	}
}

.field-type-blockreference {
	> #block-menu-block-1 {
		padding-left: 10px;

		li {
			> a {
				padding: 0 0 5px 10px;

			}

			> span,
			&.leaf.level-1 {
				font-family: $fontHeadlines;
				font-size: 24px;

				margin: 1rem 0;
				padding: 0px;
			}

			&.leaf a {
				padding-left: 0;
			}
			&.leaf.level-1 {
				padding-left: 0;

				a {
					padding: 0;

					font-style: normal;
				}
			}

			&.expanded {
				> .menu {
					padding-left: 15px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
