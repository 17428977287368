.paragraphs-item-downloads-grid {
  .paragraphs-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 40px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      /* Nur eine Spalte für mobile Geräte */
    }

    .paragraphs-item-special-download {
      margin-bottom:0;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }

      .field-name-field-download img {
        display: none;
      }
      .field-name-field-foto.field-type-image{
        overflow:hidden;
        &:before{
          content:url(/sites/all/themes/bootstrap_vdr/img/Download.svg);
          position:absolute;
          z-index:1;
          width:50px;
          height:50px;
          right:18px;
          bottom:15px;
        }
        img{
          transform:scale(1);
          transition:all .3s ease-in-out 0s;
        }
      }
      a{
        &:hover{
          .field-name-field-foto.field-type-image img{
            transform:scale(1.2);
            transition:all .3s ease-in-out 0s;
          }
        }
      }
      .field-name-field-anrisstext.field-type-text-long{
        font-style: italic;
        font-size:15px;
        line-height:19px;
        color:#000;
        margin-top:11px;
      }
    }
  }
  .field-name-field-ueberschrift{
    font-family:"Titillium Web", sans-serif;
    color:#005FA0;
    font-weight:700;
    font-size:21px;
    line-height:25px;
    margin-bottom:23px;
  }
  .paragraphs-items.paragraphs-items-field-element.paragraphs-items-field-element-teaser.paragraphs-items-teaser{
    margin-bottom:57px;

    @media screen and (max-width: 767px) {
      margin-bottom: 27px;
    }
  }
}
