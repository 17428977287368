.front {
    #block-block-5 .block-title{
		@include vdr-icon(25, '../img/LinkedIn-negativ.svg');

		&:before {
			background-color: $vdr-font-blue;
		}
	}
	#block-block-3 .block-title{
		@include vdr-icon(25, '../img/Twitter-negativ.svg');

		&:before {
			background-color: $vdr-font-blue;			
		}
	}
	#block-block-5 .pd-logo{
		@include vdr-icon(60, '../img/LinkedIn-negativ.svg');

		&:before {
			background-color: $vdr-font-blue;
		}
	}
	#block-block-3 .pd-logo{
		@include vdr-icon(60, '../img/Twitter-negativ.svg');

		&:before {
			background-color: $vdr-font-blue;			
		}
	}

	#block-block-3,
	#block-block-5 {
		.block-title {
			font-size: 24px;
			font-weight: 600;
			
			color: #fff;
			background-color: $vdr-font-blue;

			margin-top: 0;
			margin-bottom: 0;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		border: 1px solid #0063A3;
	}
	.twitter-block, .linkedin-block {
		min-height: 375px;
		max-height: 375px;
		@include breakpoint($screen-md) {
			height: 100%;
		}
		@include breakpoint($screen-lg) {
			max-height: 450px;
		}
		overflow-y: auto;
	}
}

.preload-dummy,
.preload-dummy .dummy-form {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.preload-dummy {
	position: absolute;
	top: 0;
	
	padding: 35px 40px;
	height: 100%;

	background-color: $vdr-font-blue;
	color: #fff;

	font-size: 18px;

	> * {
		margin-bottom: 10px;
	}
	
	.pd-logo,
	.pd-text,
	.pd-submit {
		display: block;
	}

	.pd-logo {
		margin-bottom: 40px;
	}
	.pd-text {
		text-align: center;
	}

	a {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: #fff
		}
	}	

	&.youtube-dummy {
		width: 100%;
		flex-direction: row;
		.pd-text,
		.dummy-form {
			flex-direction: row;
			width: 100%;
			flex-basis: 100%;
		}
		.pd-submit {
			margin-bottom: 0;
		}
	}

	.pd-check {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		input {
			margin-top: 0;
			margin-right: 10px;
		}
		label {
			font-weight: 400;
			margin-bottom: 0;
		}
	}

	.pd-submit {		
		color: $vdr-font-blue;
		background-color: #fff;
		font-size: 22px;
		font-weight: 600;

		padding: 5px 10px;
		margin-bottom: 20px;

		border-radius: 0;
		border-style: none;
		border-color: $vdr-font-blue;

		&:hover {
			background-color: #DCE9F2;
		}
	}
}

#block-system-main .paragraphs-items-field-themenbild .field-items .row {
	.field-name-field-block-reference {
		height: 100%;
		> section {
			height: 100%;
		}	
	}  
}

