.mm-menu {
  color: $vdr-font-blue;
  background-color: $colBG;
  font-family: $fontHeadlines;

  .mm-listview > li .mm-next:after,
  .mm-menu .mm-listview > li .mm-arrow:after {
    border-color: $colFont;
  }
  ul.mm-listview{
    > li{
      a.mm-next{
        width: 100%;
      }
      a.nolink{
        pointer-events: none;
      }
    }
  }
}
.mm-listview {
  font-size: 18px;
  line-height: 22px;
  /*> li:not(.mm-divider):after {
    border-bottom: none;
  }*/
}
.mm-prev:before,
.mm-next:after,
.mm-arrow:after {
  height: 12px;
  width: 12px;
}

.mm-listview > li, .mm-listview > li::after, .mm-listview > li .mm-next, .mm-listview > li .mm-next::before {
  border-color: transparent;
}

.mm-menu {
  .mm-panels {
    .mm-panel.mm-hassearch {
      /*top: 20px;*/
      padding-top: 54px;
      &#mm-1{
        .mm-search {
          padding: 18px 54px 10px 16px !important;
        }
        .mmenu-search-btn{
          display: inline;
          position: absolute;
          right: 0;
          top: 0;
          height: 45px;
          width: 44px;
          &:before{
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            margin-left: -25px;
            margin-top: 18px;
          }
        }
        &:before{
          display: none;
        }
      }
      .mm-navbar{
        border-bottom: 0;
      }

      .mm-search {
        padding: 6px 54px 10px 16px;
        input {
          background-color: $bg-gradient-grey-dark;
          color: $colFont;
          padding: 0;
          font-size: 15px;
          &::-webkit-input-placeholder {
            text-align: center;
          }

          &:-moz-placeholder { /* Firefox 18- */
            text-align: center;
          }

          &::-moz-placeholder {  /* Firefox 19+ */
            text-align: center;
          }

          &:-ms-input-placeholder {
            text-align: center;
          }
        }
      }

      .mmenu-search-btn{
        display: inline;
        position: absolute;
        right: 0;
        top: 28px;
        height: 45px;
        width: 44px;
        &:before{
          background-size: 20px 20px;
          width: 20px;
          height: 20px;
          margin-left: -25px;
          margin-top: 18px;
        }
      }

      .mm-listview{
        margin: 0 -20px;
        > li {
          .mm-next:after{
            border-color: $vdr-font-blue;
            right: 26px;
          }
          > a, > span{
            padding: 13px 10px 13px 16px;
          }
          > span.language-link{
            padding: 0;
          }
          &.special_menu_vdr_intern.level-1 + .special_menu_vdr_intern.level-1{
            margin-top: -36px;
            margin-bottom: 36px;
          }
          &.special_menu_vdr_intern{
            display: none;
          }
        }

      }
      #mm-language_switcher{
        margin-right: 0;
        ul{
          &.mm-listview{
            margin: 0px;
            padding: 13px 10px 13px 16px;
          }
          > li{
            > a{
              padding: 0;
              display: inline;
            }
            &:after{
              position: static;
            }
            > span{
              padding: 0;
              display: inline;
            }

          }
        }
      }

      .mm-btn{
        border: 0;
        border-radius: 0;
        background-color: transparent;
        padding: 13px 10px 13px 16px;
      }
    }
  }
}